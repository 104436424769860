import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

// Material UI
import {
  Button,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
} from "@mui/material";
import { Add as AddIcon, Visibility } from "@mui/icons-material";

//  Components
import Header from "../../../components/layouts/header/Header";

// Services
import * as contractServices from "../../../services/documentations/ContractServices";
import { getDescendantProp } from "../../../utils/helperFunctions";
import DocumentDialog from "../../../components/dialogs/documentDialog/DocumentDialog";
import Column from "../../../models/table/Column";
import Table from "../../../components/table/Table";
import { OPERATORS_CONTAINING, SUBJECT_TYPES } from "../../../constants";
import { downloadFile } from "../../../utils/downloadCSV";
import StatusGenerator from "../../../components/table/StatusGenerator";
import Operator from "../../../models/table/Operator";
import OperatorGenerator from "../../../components/table/OperatorGenerator";
import { getDownloadCommands } from "../../../services/structure/StructureRequestService";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const ViewTypeofContracts = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [filters, setFilters] = useState([]);
  const [dataID, setDataID] = useState();
  const [status, setStatus] = useState(undefined);
  const [isShow, setIsShow] = useState(false);
  const [contractType, setContractType] = useState();
  const paramsData = new URLSearchParams(document.location.search);
  useEffect(() => {
    let additionalFilters = [];
    const type = paramsData.get("contractType");
    setContractType(type);
    const contractTypeId = paramsData.get("contractID");
    if (contractTypeId) {
      additionalFilters.push({
        column: "contractType._id",
        operator: "equalsTo",
        value: contractTypeId,
      });
    }

    paginationFetchingHandler(additionalFilters);
  }, [limit, offset, filters]);

  const paginationFetchingHandler = async (additionalFilters) => {
    await getAllContracts([...filters, ...additionalFilters]);
  };

  const getAllContracts = async (filters = []) => {
    try {
      setLoading(true);
      const { contracts, total } =
        await contractServices.getContractsWithFilters({
          limit: limit || 10,
          offset: offset || 0,
          filters: encodeURIComponent(JSON.stringify([...filters])),
        });
      const _respDatas = contracts.map((type) => ({
        ...type,
        id: type._id,
      }));
      setDatas(_respDatas);
      setTotal(total);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };
  const headerData = {
    title:
      contractType === "order"
        ? "Əmrlərin məlumatları"
        : contractType === "agreement"
        ? "Müqavilə məlumatları"
        : "Ərizə məlumatları",
    variant: "h3",
  };

  const docType = {
    order: "Əmr",
    agreement: "Müqavilə",
    application: "Ərizə",
  };

  const onCloseHandler = () => {
    setIsShow(false);
  };

  // const handleOpenDialog = (data) => {
  //   setDataDialog(data);
  //   setIsShow(true);
  // };

  // const handleChooseVersion = (id) => {
  //   if (dataDialog.oldVersions.length > 0) {
  //     const foundedV = dataDialog.oldVersions.find(
  //       (item) => item.contract._id === id
  //     );
  //     if (!foundedV && dataDialog._id === id) {
  //       setVersionData(dataDialog);
  //       setShowContract(true);
  //       return;
  //     }
  //     setVersionData(foundedV.contract);
  //   } else {
  //     setVersionData(dataDialog);
  //   }
  //   setShowContract(true);
  // };

  // const handleCloseShowContract = () => {
  //   setShowContract(false);
  //   setVersionData(null);
  // };

  // const handleCloseDialog = () => {
  //   setIsShow(false);
  //   setDataDialog(null);
  //   // setVersionData(null);
  // };

  // const handlerGetVersionsObject = () => {
  //   const versions = [];

  //   versions.push({
  //     title: `Son versiya`,
  //     description: `Tarix: ${new Date(dataDialog.createdAt).toLocaleString(
  //       "az-AZ",
  //       {
  //         hour12: false,
  //       }
  //     )}`,
  //     value: dataDialog._id,
  //   });

  //   dataDialog.oldVersions.map((item, index) => {
  //     const obj = {
  //       title: `v.${index + 1}`,
  //       description: `Tarix: ${new Date(item.contract.createdAt).toLocaleString(
  //         "az-AZ",
  //         { hour12: false }
  //       )}`,
  //       value: item.contract._id ? item.contract._id : dataDialog._id,
  //     };
  //     versions.push(obj);
  //   });

  //   return versions;
  // };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const downloadHandler = async (columnsForExport) => {
    try {
      const colsExp = [];

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        colsExp.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      let additionalFilters = [];

      const contractTypeId = paramsData.get("contractType");
      if (contractTypeId) {
        additionalFilters.push({
          column: "contractType._id",
          operator: "equalsTo",
          value: contractTypeId,
        });
      }

      const encodeColumns = encodeURIComponent(JSON.stringify(colsExp));
      const encodeFilters = encodeURIComponent(
        JSON.stringify([...filters, ...additionalFilters])
      );
      const resp = await getDownloadCommands({
        columns: encodeColumns,
        filter: encodeFilters,
        isWithFilter: true,
      });

      if (resp) downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  const columns = [
    new Column({
      path: "contractType.label",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "contractType.code",
      headerName: "Kod",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "contractType.templateName",
      headerName: "Faylın adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "docModel",
      headerName: "Document Model",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: SUBJECT_TYPES,
    }),
    new Column({
      path: "subjects.subject.firstname",
      headerName: "Əməkdaşın adı",
      filterType: OPERATORS_CONTAINING.TEXT,

      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      path: "subjects.subject.lastname",
      headerName: "Əməkdaşın soyadı",
      filterType: OPERATORS_CONTAINING.TEXT,

      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.OPTION,
      isStaticColumn: true,

      renderCell: (row, column) => {
        const approvers = [];
        row.verifiers.map((item, index) => {
          approvers.push({
            index: index + 1,
            employee:
              item.employee?.lastname +
              " " +
              item.employee?.firstname +
              " " +
              item.employee?.fathername,
            status: item.status,
          });
        });
        return (
          <StatusGenerator
            approvement={approvers}
            value={getDescendantProp(row, column.path)}
            docType={"generalDocuments"}
          />
        );
      },
    }),
    new Column({
      path: "#",
      headerName: "Operatorlar",
      isStaticColumn: true,
      disableColSearch: true,
      renderCell: (row, column) => {
        const operators = [
          new Operator({
            icon: <Visibility />,
            onClick: () => {
              setDataID(row._id);

              setIsShow(true);
            },
            type: 0,
          }),
        ];

        return <OperatorGenerator operators={operators} />;
      },
    }),
  ];

  let button_group = [
    ...(status === "pending"
      ? [
          {
            // onClick: () =>
            //   renderCommmenDialog({ isShow: true, type: "approved" }),
            label: "İmzala",
            color: "success",
            gap: "me-1",
          },
          {
            // onClick: () =>
            //   renderCommmenDialog({ isShow: true, type: "rejected" }),
            label: "İmtina et",
            color: "error",
          },
        ]
      : []),
  ];
  return (
    <>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Header data={headerData} />
        </Grid>
        <Grid item>
          <Button
            sx={{ marginRight: "5px" }}
            variant="contained"
            color="primary"
            onClick={() => {
              if (contractType === "order") {
                navigate("/documentation/orders");
              } else if (contractType === "agreement") {
                navigate("/documentation/contracts");
              } else if (contractType === "application") {
                navigate("/documentation/application");
              }
            }}
          >
            {docType[contractType]}lər
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (contractType === "order") {
                navigate("/documentation/command/create");
              } else if (contractType === "agreement") {
                navigate("/documentation/contract/create");
              } else if (contractType === "application") {
                navigate("/documentation/application/create");
              }
            }}
          >
            <AddIcon />
            {docType[contractType]} Yarat
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} mb={6}>
          {/* {datas && datas.length > 0 ? ( */}
          <Table
            columns={columns}
            data={datas}
            totalRows={total}
            offset={offset}
            setOffset={(value) => setOffset(value)}
            limit={limit}
            setLimit={(value) => setLimit(value)}
            isLoading={loading}
            applyFiltersHandler={applyFiltersHandler}
            cancelAppliedFilters={cancelAppliedFiltersHandler}
            downloadHandler={downloadHandler}
          />
          {/* ) : (
            <Card>
              <CardContent>
                <Typography variant="h3" textAlign="center">
                  Hər hansı bir məlumat tapılmadı və ya xəta baş verdi
                </Typography>
              </CardContent>
            </Card>
          )} */}
        </Grid>
      </Grid>
      {/* {dataDialog && (
        <VersionDialog
          isShow={isShow}
          headerText={"Müqavilə dəyişiklikləri"}
          onClose={handleCloseDialog}
          onSelect={handleChooseVersion}
          data={handlerGetVersionsObject()}
        />
      )} */}
      {isShow && (
        // <SigningDialog
        //   open={showContract}
        //   onClose={handleCloseShowContract}
        //   data={versionData}
        //   type={versionData.status}
        // />
        <DocumentDialog
          id={dataID}
          onClose={onCloseHandler}
          open={isShow}
          buttonGroup={button_group}
          setStatus={setStatus}
        />
      )}
    </>
  );
};

export default ViewTypeofContracts;
