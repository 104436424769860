import React from "react";
import { formatDateTime } from "../../../utils/helperFunctions";

export const GROUP_NAME = {
  "document-update": "Yenilənmiş Şəxsi işi - Sənədlər və şəkilləri",
  "document-delete": "Silinmiş Şəxsi işi - Sənədlər və şəkilləri",
  "document-create": "Yaradılmış Şəxsi işi - Sənədlər və şəkilləri",
  "personal-info-update": "Yenilənmiş Şəxsi məlumatlar",
  "personal-info-delete": "Silinmiş Şəxsi məlumatlar",
  "personal-info-create": "Yaradılmış Şəxsi məlumatlar",
  "bank-info-update": "Yenilənmiş Bank məlumatları",
  "bank-info-delete": "Silinmiş Bank məlumatları",
  "bank-info-create": "Yaradılmış Bank məlumatları",
  "labor-activity-update": "Yenilənmiş Əmək fəaliyyəti",
  "labor-activity-delete": "Silinmiş Əmək fəaliyyəti",
  "labor-activity-create": "Yaradılmış Əmək fəaliyyəti",
  "relationship-info-update": "Yenilənmiş Qohumluq əlaqəsi",
  "relationship-info-delete": "Silinmiş Qohumluq əlaqəsi",
  "relationship-info-create": "Yaradılmış Qohumluq əlaqəsi",
  "contact-info-update": "Yenilənmiş Əlaqə məlumatları",
  "contact-info-delete": "Silinmiş Əlaqə məlumatları",
  "contact-info-create": "Yaradılmış Əlaqə məlumatları",
  "education-info-update": "Yenilənmiş Təhsil məlumatları",
  "education-info-delete": "Silinmiş Təhsil məlumatları",
  "education-info-create": "Yaradılmış Təhsil məlumatları",
  "academic-degree-update": "Yenilənmiş Elmi dərəcələri göstər",
  "academic-degree-delete": "Silinmiş Elmi dərəcələri göstər",
  "academic-degree-create": "Yaradılmış Elmi dərəcələri göstər",
  "social-status-update": "Yenilənmiş Sosial Statusları göstər",
  "social-status-delete": "Silinmiş Sosial Statusları göstər",
  "social-status-create": "Yaradılmış Sosial Statusları göstər",
  "supplemental-wage-update": "Yenilənmiş Əməkhaqqına əlavələri göstər",
  "supplemental-wage-delete": "Silinmiş Əməkhaqqına əlavələri göstər",
  "supplemental-wage-create": "Yaradılmış Əməkhaqqına əlavələri göstər",
  "reprimand-and-reward-update":
    "Yenilənmiş Mükafat, təltif və tənbehlərini göstər",
  "reprimand-and-reward-delete":
    "Silinmiş Mükafat, təltif və tənbehlərini göstər",
  "reprimand-and-reward-create":
    "Yaradılmış Mükafat, təltif və tənbehlərini göstər",
  contract_general_info: "Sənəd haqqında ümumi məlumat",
  contract_request_general_info:
    "Sənəd imzalanması sorğusu haqqında ümumi məlumat",
  contract_subjects_general_info:
    "Əmək müqaviləsini bağlayan tərəflər haqqında məlumat",
  for_protection: "Əməyin mühafizəsi üzrə",
  for_vacation: "Məzuniyyət üzrə",
  for_wage: "Əmək haqqı üzrə",
  for_work_vacation_time: "İş və istirahət vaxtı üzrə",
  information_on_changes:
    "Əmək müqaviləsinə dəyişikliklər və əlavələr edilməsi barədə məlumat",
  labour_function: "İşçinin əmək funksiyası",
  labour_period: "Əmək müqaviləsininin müddəti",
  administrative: "Vəzifəli şəxsin məlumatları",
  jobRelated: "Əməkdaşın vəzifə məlumatları",
  labour_termination: "Əmək müqaviləsinə xitam verilməsi",
  physical_education_sport: "Bədən tərbiyəsi və idman üzrə",
  regulation_property_relations: "Mülkiyyət münasibətlərinin tənzimlənməsi",
  social_protection: "Sosial müdafiə məsələləri",
  other: "Digər",
  signer_general_info: "İmzalayan haqqında ümumi məlumat",
  vacation_general_info: "Məzuniyyət haqqında ümumi məlumat",
  approvers_general_info: "Təsdiqləyən şəxs(lər) haqqında ümumi məlumat",
  document_general_info: "Sənəd haqqında ümumi məlumat",
  employment_general_info: "Təyinat haqqında ümumi məlumat",
  request_general_info: "Sorğu haqqında ümumi məlumat",
  subject_general_info: "Namizəd haqqında ümumi məlumat",
  structure_general_info: "Təyinat haqqında məlumat",
  changes_general_info: "Dəyişiklik haqqında ümumi məlumat",
  approver_general_info: "Təsdiqləyən haqqında ümumi məlumat",
  labourPeriod: "Əmək müqaviləsininin müddəti",
  wage: "Əmək haqqı üzrə",
  protection: "Əməyin mühafizəsi üzrə",
  workVacationTime: "İş və istirahət vaxtı üzrə",
  vacation: "Məzuniyyət üzrə",
  physicalEducationSport: "Bədən tərbiyəsi və idman üzrə",
  socialProtection: "Sosial müdafiə məsələləri",
  regulationPropertyRelation: "Mülkiyyət münasibətlərinin tənzimlənməsi",
  informationAboutParties: "Tərəflər haqqında məlumat",
};

export const LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS = {
  "labour-contract": {
    contract_general_info: {
      verifiers: (value) => {
        let string = "";

        value?.map((item, index) => {
          string =
            index === 0
              ? formatDateTime(item.date).toString()
              : `${string}, ${formatDateTime(item.date).toString()}`;
        });
        return string;
      },
      contractType: (value) => {
        return value === "labour-contract"
          ? "Əmək müqaviləsi"
          : value === "labourContractUpdate"
          ? "Əmək müqaviləsinə dəyişiklik müqaviləsi"
          : "--";
      },
    },
    contract_request_general_info: {},
    contract_subjects_general_info: {
      empWorkPlaceType: (value) => {
        switch (value) {
          case "main":
            return "Əsas";
          default:
            return "?Əlavə";
        }
      },
    },
    for_protection: {
      providedProtectiveEquipment: (value) => {
        let string = "";

        value?.map((item, index) => {
          string = index === 0 ? item : string + ", " + item;
        });
        return string;
      },
      providedFood: (value) => {
        let string = "";

        value?.map((item, index) => {
          string = index === 0 ? item : string + ", " + item;
        });
        return string;
      },
      instructionalPeriod: (value) => {
        let string = "";

        value.length > 0 &&
          value?.map((item, index) => {
            string = index === 0 ? item : string + ", " + item;
          });
        return string;
      },
    },
    for_vacation: {
      vacationDaysCount: (value) => value + " gün",
      forWorkConditions: (value) => value + " gün",
      totalVacationDays: (value) => value + " gün",
      socialHouseholdBenefit: (value) => value + " gün",
    },
    for_wage: {
      workingCondition: (value) => {
        return `${value.parent} - ${value.name}`;
      },
      currentSalary: (value) => value + "₼",
      wage: (value) => value + "₼",
      extraDueCondition: (value) => value + "₼",
      wageBonus: (obj) => obj.value + "₼",
    },
    for_work_vacation_time: {
      offDays: (value) => {
        let string = "";

        value?.map((item, index) => {
          string = index === 0 ? item : string + ", " + item;
        });
        return string;
      },
    },
    information_on_changes: {
      changesInEmploymentContract: (value) => {
        // let string = "";
        // value?.forEach((item, index) => {
        //   string += index === 0 ? item : `, ${item}`;
        // });

        return value || "-";
      },
    },
    labour_function: {
      labourFunctions: (value) => {
        let string = "-";
        value?.map((item, index) => {
          string = index === 0 ? item : string + `, ${item}`;
        });
        return string;
      },
    },
    labour_period: {
      contractPeriodType: (value) => {
        switch (value) {
          case "indefinite":
            return "Müddətsiz";
          case "term":
            return "Müddətli";
          default:
            return "? Müddətli";
        }
      },
      probationPeriod: (obj) => {
        switch (obj.type) {
          case "day":
            return `${obj.value} gün`;
          case "week":
            return `${obj.value} həftə`;
          case "month":
            return `${obj.value} ay`;
          case "year":
            return `${obj.value} il`;
          default:
            return "Qeyri-müəyyən tip";
        }
      },
      probationRefusalTime: (obj) => {
        switch (obj.type) {
          case "day":
            return `${obj.value} gün`;
          case "week":
            return `${obj.value} həftə`;
          case "month":
            return `${obj.value} ay`;
          case "year":
            return `${obj.value} il`;
          default:
            return "Qeyri-müəyyən tip";
        }
      },
    },
    labour_termination: {
      contractTerminationCases: (value) => {
        let string = "-";
        value?.map((item, index) => {
          string = index === 0 ? item : string + `, ${item}`;
        });
        return string;
      },
    },
    physical_education_sport: {
      otherAdditionalConditions: (value) => {
        return `? ${value.toString}`;
      },
    },
    regulation_property_relations: {
      personalPropertiesUsedByEmployee: (value) => {
        let string = "-";
        value?.map((item, index) => {
          string = index === 0 ? item : string + `, ${item}`;
        });
        return string;
      },
      dividendAmount: (value) => value + "₼",
    },
    social_protection: {
      monthlyInsuranceFee: (value) => value + "₼",
      extraSumInsured: (value) => value + "₼",
    },
  },
  "vacation-application": {
    document_general_info: {
      documentType: (value) => {
        switch (value) {
          case "VacationRequest":
            return "Məzuniyyət sorğusu";
          case "vacation-application":
            return "Məzuniyyət ərizəsi";
          default:
            return value;
        }
      },
    },
    request_general_info: {
      requestType: (value) => {
        switch (value) {
          case "VacationRequest":
            return "Məzuniyyət sorğusu";
          case "vacation-application":
            return "Məzuniyyət ərizəsi";
          default:
            return value;
        }
      },
    },
    approvers_general_info: {
      requestSigners: (value) => {
        const elements = value.map((item, index) => {
          const emp = item.staff.employee[0];
          const name = `${emp.lastname} ${emp.firstname} ${emp.fathername}`;
          return index === 0 ? (
            name
          ) : (
            <React.Fragment key={index}>
              , <br />
              {name}
            </React.Fragment>
          );
        });

        return <>{elements}</>;
      },
    },
    vacation_general_info: {
      vacationType: (value) => {
        switch (value) {
          case "main":
            return "Əsas əmək məzuniyyəti";
          case "additional":
            return "Əlavə əmək məzuniyyəti";
          case "creative":
            return "Yaradıcılıq məzuniyyəti";
          case "educational":
            return "Təhsil məzuniyyəti";
          case "social":
            return "Sosial məzuniyyət";
          case "paid":
            return "Ödənişsiz məzuniyyət";
          default:
            return "Qeyri-müəyyən məzuniyyət tipi";
        }
      },
    },
  },
  "employment-application": {
    document_general_info: {
      documentType: (value) => {
        return value === "employment-application"
          ? "İşə təyin olunma ərizəsi"
          : value;
      },
    },
    request_general_info: {
      requestType: (value) =>
        value === "EmploymentRequest" ? "İşə təyin olunma sorğusu" : value,
    },
    approvers_general_info: {
      requestSigners: (value) => {
        const elements = value.map((item, index) => {
          const emp = item.staff.employee[0];
          const name = `${emp.lastname} ${emp.firstname} ${emp.fathername}`;
          return index === 0 ? (
            name
          ) : (
            <React.Fragment key={index}>
              , <br />
              {name}
            </React.Fragment>
          );
        });

        return <>{elements}</>;
      },
    },
  },
  "labour-order": {
    contract_general_info: {
      contractType: (value) =>
        value === "labour-order" && "Əmək müqaviləsi əmri",
    },
  },
  "vacation-order": {
    contract_general_info: {
      contractType: (value) => value === "vacation-order" && "Məzuniyyət əmri",
    },
    vacation_general_info: {
      vacationType: (value) => {
        switch (value) {
          case "main":
            return "Əsas əmək məzuniyyəti";
          case "additional":
            return "Əlavə əmək məzuniyyəti";
          case "creative":
            return "Yaradıcılıq məzuniyyəti";
          case "educational":
            return "Təhsil məzuniyyəti";
          case "social":
            return "Sosial məzuniyyət";
          case "paid":
            return "Ödənişsiz məzuniyyət";
          default:
            return "Qeyri-müəyyən məzuniyyət tipi";
        }
      },
    },
  },
  "structure-request-service": {
    changes_general_info: {
      changesUnis: (value) => {
        const elements = value.map((item, index) => {
          const changeTarget = item.key;
          const comment = item.value;
          return index === 0 ? (
            changeTarget + " - " + `"${comment}"`
          ) : (
            <React.Fragment key={index}>
              , <br />
              {changeTarget + " - " + `"${comment}"`}
            </React.Fragment>
          );
        });

        return <>{elements}</>;
      },
    },
  },
};

export const LABOUR_CHANGE_INPUT_FIELDS = {
  // labour_period
  contractPeriodType: (value) => {
    const options = [
      { key: "term", name: "müddətli" },
      { key: "indefinite", name: "müddətsiz" },
    ];
    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  probationPeriod: (value) => {
    const options = [
      { key: "day", name: "gün" },
      { key: "week", name: "həftə" },
      { key: "month", name: "ay" },
      { key: "year", name: "il" },
    ];
    const type = "input-select";
    const customFields = ["value", "type"];

    const validations = (field) => {
      // field will come like {value, type, changed} object
      let errMsg;
      let isValid = true;

      if (field.value && !field.type) {
        errMsg = "Müddət tipi seçilməlidir";
        isValid = false;
      } else if (!field.value && field.type) {
        errMsg = "Müddət seçilməlidir";
        isValid = false;
      }

      return {
        errMsg,
        isValid,
      };
    };

    return {
      options,
      type,
      validations,
      customFields,
    };
  },
  probationRefusalTime: (value) => {
    const options = [
      { key: "day", name: "gün" },
      { key: "week", name: "həftə" },
      { key: "month", name: "ay" },
      { key: "year", name: "il" },
    ];
    const type = "input-select";
    const customFields = ["type", "value"];
    const validations = {};

    return {
      options,
      type,
      validations,
      customFields,
    };
  },
  reasonOfTemporaryContract: (value) => {
    const options = [];
    const type = "text";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  contractStartDate: (value) => {
    const options = [];
    const type = "date";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  contractEndDate: (value) => {
    const options = [];
    const type = "date";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  contractPeriod: (value) => {
    const options = [];
    const type = "date";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  jobStartDate: (value) => {
    const options = [];
    const type = "date";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  // for_wage
  currentSalary: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  wage: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  wageBonus: (value) => {
    // const options = [
    //   { key: "manat", name: "manat" },
    //   { key: "percent", name: "faiz" },
    // ];
    const options = [];
    const type = "number";
    // const customFields = ["type", "value"];
    const validations = {};

    return {
      options,
      type,
      validations,
      // customFields,
    };
  },
  bonusPeriod: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  givenBonus: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  workingCondition: (value) => {
    const options = [
      { key: "term", name: "müddətli" },
      { key: "indefinite", name: "müddətsiz" },
    ];
    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  extraDueCondition: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  overtime: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  wageDayOfWeek: (value) => {
    const options = [
      { key: "monday", name: "Bazar ertəsi" },
      { key: "tuesday", name: "Çərşənbə axşamı" },
      { key: "wednesday", name: "Çərşənbə" },
      { key: "thursday", name: "Cümə axşamı" },
      { key: "friday", name: "Cümə" },
      { key: "saturday", name: "Şənbə" },
      { key: "sunday", name: "Bazar" },
    ];
    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  wageDaysOfMonth: (value) => {
    const options = [];

    for (let index = 1; index < 32; index++) {
      options.push({ key: index, name: index });
    }

    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  wageDayOfMonth: (value) => {
    const options = [];

    for (let index = 1; index < 32; index++) {
      options.push({ key: index, name: index });
    }
    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  employeeBankName: (value) => {
    const options = [
      {
        name: "Azərbaycan Beynəlxalq Bankı",
        voen: "9900001881",
        code: "805250",
      },
      { name: "Accessbank", voen: "1400057421", code: "505000" },
      { name: "AFB Bank", voen: "1301703781", code: "503217" },
      { name: "Azərbaycan Sənayə Bankı", voen: "9900007981", code: "509664" },
      { name: "Azər-Türk bankı", voen: "9900006111", code: "507699" },
      { name: "Bank Avrasiya", voen: "1700792251", code: "505129" },
      { name: "Bank of Baku", voen: "1700038881", code: "506924" },
      { name: "BTB Bank", voen: "1302164881", code: "501145" },
      { name: "Bank Respublika", voen: "9900001901", code: "505668" },
      { name: "Bank VTB (Azərbaycan)", voen: "1400117231", code: "506623" },
      { name: "Expressbank", voen: "1500031691", code: "505099" },
      { name: "Kapital Bank", voen: "9900003611", code: "200004" },
      { name: "Melli İran Bankı ( Bakı )", voen: "1300036291", code: "509761" },
      { name: "Muğanbank", voen: "1400122681", code: "506162" },
      { name: "Naxçıvanbank", voen: "0200432771", code: "505152" },
      { name: "YELO Bank", voen: "9900014901", code: "507064" },
      { name: "PAŞA Bank", voen: "1700767721", code: "505141" },
      { name: "Rabitəbank", voen: "9900001061", code: "506399" },
      { name: "Turanbank", voen: "1300016391", code: "507462" },
      { name: "Unibank", voen: "1300017201", code: "505754" },
      { name: "Xalq Bank", voen: "2000296061", code: "505055" },
      {
        name: "Yapı Kredi Bank Azərbaycan",
        voen: "9900009021",
        code: "509987",
      },
      { name: "Ziraat Bank Azərbaycan", voen: "1303953611", code: "512372" },
    ];

    for (let index = 0; index < options.length; index++) {
      options[index] = { ...options[index], key: index };
    }

    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  conditionsOfSalaryPayment: (value) => {
    const options = [];
    const type = "text";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  // for_protection
  providedProtectiveEquipment: (value) => {
    const options = [];
    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  providedFood: (value) => {
    const options = [];
    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  instructionalPeriod: (value) => {
    const options = [];
    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  // for_work_vacation_time
  workingMode: (value) => {
    const options = [
      {
        key: "standard",
        name: "Standart",
      },
      {
        key: "shift",
        name: "Növbəli",
      },
      {
        key: "flexible",
        name: "Sürüşkən",
      },
    ];
    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  jobStartHour: (value) => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  jobEndHour: (value) => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  lunchStartHour: (value) => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  lunchEndHour: () => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  partTimeWorkHour: () => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  workTurnCount: () => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  firstTurnStartHour: () => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  firstTurnEndHour: () => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  secondTurnStartHour: () => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  secondTurnEndHour: () => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  thirdTurnStartHour: () => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  thirdTurnEndHour: () => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  fourthTurnStartHour: () => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  fourthTurnEndHour: () => {
    const options = [];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  providedCar: () => {
    const options = [
      { key: false, name: "xeyr" },
      { key: true, name: "bəli" },
    ];
    const type = "time";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  offDays: () => {
    const options = [
      { key: "monday", name: "Bazar ertəsi" },
      { key: "tuesday", name: "Çərşənbə axşamı" },
      { key: "wednesday", name: "Çərşənbə" },
      { key: "thursday", name: "Cümə axşamı" },
      { key: "friday", name: "Cümə" },
      { key: "saturday", name: "Şənbə" },
      { key: "sunday", name: "Bazar" },
    ];
    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
      multiple: true,
    };
  },
  // for_wage
  employeeJobYear: (value) => {
    const options = [];
    const type = "date-date";
    const validations = {};
    const customFields = ["start", "end"];

    return {
      options,
      type,
      validations,
      customFields,
    };
  },
  vacationDaysCount: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  forWorkConditions: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  forSeniorityLevel: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  forParental: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  forCollectiveAgreement: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  totalVacationDays: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  socialHouseholdBenefit: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  aboutEventOrPayer: (value) => {
    const options = [];
    const type = "text";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  // physical_education_sport
  physicalEducationAndSportsConditions: (value) => {
    const options = [];
    const type = "text";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  otherAdditionalConditions: (value) => {
    const options = [];
    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  // social_protection
  monthlyInsuranceFee: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  extraSumInsured: (value) => {
    const options = [];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  // regulation_property_relations
  shareOwnershipOfEnterprise: (value) => {
    const options = [
      { key: "term", name: "müddətli" },
      { key: "indefinite", name: "müddətsiz" },
    ];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  dividendAmount: (value) => {
    const options = [
      { key: "term", name: "müddətli" },
      { key: "indefinite", name: "müddətsiz" },
    ];
    const type = "number";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
  personalPropertiesUsedByEmployee: (value) => {
    const options = [];
    const type = "select";
    const validations = {};

    return {
      options,
      type,
      validations,
    };
  },
};
