import { Grid } from "@mui/material";
import Header from "../../components/layouts/header/Header";
import Table from "../../components/table/Table";
import { useState } from "react";

const header = {
  title: "Hesabatlar",
  variant: "h1",
};

const column = [];

export default function SalaryReports() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Grid sx={{ width: "100%", textAlign: "center", marginBottom: "50px" }}>
        <Header data={header} />
      </Grid>
    </>
  );
}
