import React, { useEffect, useReducer, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Api import
import {
  getDecisionChains,
  getSingleDecision,
  getUtilsEmployees,
} from "../../api/api";
import {
  decisionChainReducer,
  initialState,
} from "../../redux/DecisionChainReducer";
import AddDecisionChain from "../../components/decisionChain/AddDecisionChain";
import DeleteDecisionChain from "../../components/decisionChain/DeleteDecisionChain";
import EditDecisionChain from "../../components/decisionChain/EditDecisionChain";
import ViewDecisionChain from "../../components/decisionChain/ViewDecisionChain";
import Loading from "../../components/loading/Loading";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function DecisionChainComponent({ state, dispatch }) {
  const { decisionChains, submitted, rowId } = state;
  const [isLoading, setISLoading] = useState(false);
  const [rows, setRows] = useState([]);

  function createData(
    id,
    organization,
    structure,
    substructure,
    position,
    positionNumber,
    approveNumber,
    operations
  ) {
    return {
      id,
      organization,
      structure,
      substructure,
      position,
      positionNumber,
      approveNumber,
      operations,
    };
  }

  const createRow = () => {
    const newRows =
      decisionChains &&
      decisionChains.map((item, index) => {
        return createData(
          item.id,
          item.structure?.organization?.name,
          item.topCompanyPartRel?.source?.name,
          item.companyPartRel?.source?.name,
          item.positionPartRel?.source?.name,
          item.positionPartRel?.staff?.length,
          item.approvementChain?.length
        );
      });
    setRows(newRows);
  };

  useEffect(() => {
    dispatch({
      type: "SET_LOADING_DIALOG",
      payload: true,
    });
    const handleDecisionChains = async () => {
      try {
        const response = await getDecisionChains();

        if (response && response.data) {
          const decisionChainWithId = response?.data?.map((decisionChain) => ({
            ...decisionChain,
            id: decisionChain._id,
          }));
          dispatch({
            type: "SET_DECISION_CHAINS",
            payload: decisionChainWithId,
          });
          dispatch({
            type: "SET_LOADING_DIALOG",
            payload: false,
          });
        }
      } catch (err) {
        console.log("error: ", err);
      }
    };
    handleDecisionChains();
  }, [submitted]);

  useEffect(() => {
    createRow();
  }, [submitted, decisionChains]);

  useEffect(() => {
    if (rowId) {
      dispatch({
        type: "SET_LOADING_DIALOG",
        payload: true,
      });
      const getSingleDecisionChain = async () => {
        setISLoading(true);
        try {
          const response = await getSingleDecision(rowId);

          if (response && response.data) {
            const responseUtilsEmployees = await getUtilsEmployees(
              response.data.structure._id
            );
            dispatch({
              type: "SET_UTILS_EMPLOYEES",
              payload: responseUtilsEmployees.data,
            });
            dispatch({
              type: "SET_SELECTED_DECISION_CHAIN",
              payload: response.data,
            });
            dispatch({
              type: "SET_APPROVEMENT_CHAIN",
              payload: response.data.approvementChain,
            });
            dispatch({
              type: "SET_STRUCTURE_ID",
              payload: response.data.structure._id,
            });
            dispatch({
              type: "SET_LOADING_DIALOG",
              payload: false,
            });
          }
        } catch (err) {
          console.log("error: ", err);
        }
        setISLoading(false);
      };
      getSingleDecisionChain();
    }
  }, [submitted, rowId]);

  const handleDelete = async (row) => {
    dispatch({
      type: "SET_DELETE_DECISION_DIALOG",
      payload: true,
    });
    dispatch({
      type: "SET_ROW_ID",
      payload: row.id,
    });
  };

  const handleEdit = async (row) => {
    dispatch({
      type: "SET_EDIT_DECISION_DIALOG",
      payload: true,
    });
    dispatch({
      type: "SET_ROW_ID",
      payload: row.id,
    });
  };

  const handleView = async (row) => {
    dispatch({
      type: "SET_VIEW_DECISION_DIALOG",
      payload: true,
    });
    dispatch({
      type: "SET_ROW_ID",
      payload: row.id,
    });
  };

  return (
    <>
      <Card mb={6}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Təşkilat</TableCell>
              <TableCell align="right">Struktur</TableCell>
              <TableCell align="right">Sub-struktur</TableCell>
              <TableCell align="right">Vəzifə</TableCell>
              <TableCell align="right">Vəzifə üzrə ştat sayı</TableCell>
              <TableCell align="right">Təsdiq edənlərin sayı</TableCell>
              <TableCell align="right">Əməliyyatlar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.organization}
                </TableCell>
                <TableCell align="right">{row.structure}</TableCell>
                <TableCell align="right">{row.substructure}</TableCell>
                <TableCell align="right">{row.position}</TableCell>
                <TableCell align="right">{row.positionNumber}</TableCell>
                <TableCell align="right">{row.approveNumber}</TableCell>
                <TableCell align="right">
                  <Grid>
                    <IconButton
                      onClick={() => {
                        handleView(row);
                      }}
                      size="small"
                    >
                      <VisibilityIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        handleEdit(row);
                      }}
                      size="small"
                    >
                      <EditIcon color="warning" fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        handleDelete(row);
                      }}
                      size="small"
                    >
                      <DeleteIcon color="error" fontSize="small" />
                    </IconButton>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
            <ViewDecisionChain state={state} dispatch={dispatch} />
            <EditDecisionChain
              state={state}
              dispatch={dispatch}
              isLoading={isLoading}
            />
            <DeleteDecisionChain state={state} dispatch={dispatch} />
          </TableBody>
        </Table>
      </Card>
    </>
  );
}

function DecisionChain() {
  const [state, dispatch] = useReducer(decisionChainReducer, initialState);

  return (
    <React.Fragment>
      <Helmet title="Təsdiqləmə matrisi" />
      <ToastContainer />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Təsdiqləmə matrisi
          </Typography>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant="contained"
            endIcon={<AddCircleIcon />}
            onClick={() => {
              dispatch({
                type: "SET_ADD_DECISION_CHAIN_DIALOG",
                payload: true,
              });
            }}
          >
            <Typography noWrap>Təsdiqləmə matrisi yarat</Typography>
          </Button>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <DecisionChainComponent state={state} dispatch={dispatch} />
          <AddDecisionChain state={state} dispatch={dispatch} />
        </Grid>
      </Grid>
      {/* <Loading state={state} /> */}
    </React.Fragment>
  );
}

export default DecisionChain;
