import { Button, Dialog, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  COLORS_STATUS,
  PUBLIC_URL,
  REQUESTS_STATUS_TYPE,
} from "../../../constants";
import { getEmployeeRequestManager } from "../../../services/vacation/VacationRequest";
import DialogContent from "./DialogContent";
import CommentDialog from "./dialogParts/parts/CommentDialog";
import useRequestDialogHook from "./useRequestDialogHook";

export default function RequestDialog({
  open,
  onClose,
  id,
  fetchHandler = undefined,
  data = {},
  handlerUpdateReq,
  renderComment,
  button_group,
}) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData(id);
  }, []);

  const {
    contractData,
    fields,
    versionHandler,
    generateBgColor,
    generateHeader,
    versionsList,
    currentVersion,
    render_sections,
    sections,
    comments,
    getData,
    navList,
  } = useRequestDialogHook(id, { isLoading, setIsLoading });

  const handleOnSubmit = async (status, value) => {
    await handlerUpdateReq(contractData._id, status, value);
  };

  const handlerNavButton = (value) => {
    window.open(`/employees/${value}/view`, "_blank", "noreferrer");
    onClose();
  };

  let commDialogPaperStyle = {
    width: 1000,
    height: "auto",
    opacity: 0.95,
    borderRadius: "10px",
  };
  return (
    <>
      <Dialog
        open={open}
        sx={{
          overflow: "auto",
          width: "100%",
        }}
        className="doc-dialog"
        onClose={onClose}
      >
        <DialogContent
          fields={fields}
          href={`${BASE_URL}/employee/contract/${contractData?._id}/download`}
          buttonGroup={!isLoading ? button_group : []}
          render_sections={{ set: render_sections, state: sections }}
          comments={comments}
          contractNo={contractData?.requestNO}
          generateBgColor={generateBgColor}
          generateHeader={generateHeader}
          version={{
            versionsList,
            versionHandler,
            currentVersion,
          }}
          navigation={{
            navList: navList,
            navHandler: handlerNavButton,
            // currentNav,
          }}
        />
      </Dialog>
      <Dialog
        open={renderComment?.state?.isShow}
        onClose={() => {
          renderComment?.set({ isShow: false, type: undefined });
          // setShowSignRequestDialog(false);
        }}
        PaperProps={{ style: commDialogPaperStyle }}
        maxWidth={"1000px"}
      >
        <CommentDialog
          // setShowSignRequestDialog={() => setShowSignRequestDialog(true)}
          type={renderComment?.state?.type}
          id={id}
          onClose={() => {
            renderComment?.set({ isShow: false, type: undefined });
          }}
          closeSigningDialog={onClose}
          onSubmitHandler={handleOnSubmit}
        />
      </Dialog>
    </>
  );
}
