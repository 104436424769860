import * as React from "react";
import { Grid } from "@mui/material";

export default function FieldItem({
  label,
  value,
  group,
  largerText,
  disableLine = false,
  disableValue = false,
}) {
  // mode -> for changing employment contracts: "noneditable" : "editable" : "inedit"
  const isImage =
    typeof value === "string"
      ? value?.startsWith("data:imag")
      : "array"
      ? value?.[0]?.startsWith("data:imag")
      : null;

  const trimmedValue = React.useMemo(
    () => (typeof value === "string" ? value.trim() : value),
    [value]
  );
  return (
    <>
      <Grid
        sx={{
          marginY: "2.5px",
          width: "100%",
        }}
        component={"div"}
      >
        {/* Use Grid item or div to wrap label and value */}
        <Grid item xs={12}>
          {" "}
          {/* Or use a div */}
          <p style={{ color: "rgba(200,200,200,1)", margin: 0 }}>{label}</p>
          {isImage ? (
            <figure
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                marginTop: "10px",
              }}
            >
              <img
                src={value}
                alt={label}
                style={{ width: "200px", height: "200px", objectFit: "cover" }}
              />
              <figcaption>Şəkil dəyişdirilib</figcaption>
            </figure>
          ) : (
            // Render text value otherwise
            !disableValue && (
              <p
                style={{
                  fontSize: largerText ? "20px" : "18px",
                  margin: 0,
                  maxWidth: "400px",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                {trimmedValue || "-"}
              </p>
            )
          )}
        </Grid>
      </Grid>
      {!disableLine && <hr style={{ width: isImage ? "200px" : null }} />}
    </>
  );
}
