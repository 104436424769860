import { Grid } from "@mui/material";
import Header from "../../components/layouts/header/Header";
import Table from "../../components/table/Table";
import { useState } from "react";

const header = {
  title: "Sabit Komponentlər",
  variant: "h1",
};

const column = [];

export default function FixedComponents() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Grid sx={{ width: "100%", textAlign: "center", marginBottom: "50px" }}>
        <Header data={header} />
      </Grid>
      {/* table */}
      <Grid className="mt-4">
        <Table
          columns={column}
          data={data}
          totalRows={total}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={isLoading}
          applyFiltersHandler={(filters) => setFilters(filters)}
          cancelAppliedFilters={() => setFilters([])}
          //   downloadHandler={(cols) => downlaodHandler(cols)}
        />
      </Grid>
    </>
  );
}
