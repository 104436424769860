/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../constants";
import { setStructureDataById } from "../../../redux/features/slices/structure.slice";
import * as employeeServices from "../../../services/employees/EmployeeServices";
import * as structureServices from "../../../services/structure/StructureServices";
import styled from "styled-components";
import { GENERAL_BLOCK } from "../../../styled";
import style from "../style.module.css";
const CustomPaper = styled(Paper)(({ theme }) => ({
  height: "110px",
  overflowY: "auto",
}));
const EmployeeDialog = ({
  classes,
  open,
  onClose,
  selectedStaff,
  getStructure,
}) => {
  const { id } = useParams();
  const { structureById } = useSelector((state) => state.structure);
  const [searchValue, setSearchValue] = useState("");
  const [selected_emp, setSelectedEmp] = useState(null);
  const [confirmButtonActive, setConfirmButtonActive] = useState(false);
  const [all_employee, set_all_employee] = useState({
    options: [],
  });
  const [downloadId, setDownloadId] = useState("");
  const [more_options, set_more_options] = useState({
    offset: 0,
  });
  const [selected_wage, set_selected_wage] = useState(null);
  const [one_employee, set_one_employee] = useState({});
  const loadOptionsForEmployees = async () => {
    try {
      const search = searchValue.toLowerCase();
      const unit = search === "" ? 0 : selectedStaff?.staff_unit;

      const { data } = await employeeServices.getVacants({
        offset: more_options?.offset,
        search: search,
        staffUnit: unit,
        positionPartId: selectedStaff?.position_id,
      });
      if (data && data?.records) {
        const all_employeeMap = new Map(
          all_employee?.options.map((item) => [item.value._id, item])
        );
        const differentItems = data?.records.filter((newItem) => {
          const currentItem = all_employeeMap.get(newItem._id);
          return (
            JSON.stringify(newItem) !==
            JSON.stringify(currentItem ? currentItem.value : null)
          );
        });
        const options =
          differentItems &&
          differentItems.map((item) => ({
            key: item._id, // Ensure unique key
            value: item,
            label: `${item.firstname} ${item.lastname} ${item.fathername} ${item.gender === "male" ? "oğlu" : "qızı"}`,
          }));

        const uniqueOptions = [
          ...new Map(
            all_employee?.options
              .concat(options)
              .map((option) => [option.key, option])
          ).values(),
        ];

        set_all_employee({
          options: uniqueOptions,
          total: data?.total,
        });
        // set_one_employee(data?.records?.[0]);
        return {
          options: uniqueOptions,
        };
      }
    } catch (error) {
      console.log("error: ", error);
      return false;
    }
  };

  useEffect(() => {
    loadOptionsForEmployees();
  }, [more_options?.offset, searchValue]);

  const onSaveEmployeeId = (event, value) => {
    let selectedEmployee = all_employee?.options?.find(
      (em) => em?.value?.pin === value?.value?.pin
    )?.value;
    setSelectedEmp(selectedEmployee);
  };

  const addEmployeeToStaff = async () => {
    try {
      if (structureById?.isVerified === true) {
        const payload = {
          structureId: structureById._id,
          positionId: selectedStaff?.position_id,
          jobStartDate: moment().format("MM/DD/YYYY"),
          agreementDate: moment().format("MM/DD/YYYY"),
          contractId: downloadId,
          staffId: selectedStaff?.staff_id,
          employeeId: selected_emp._id,
          // supplementalWage: selected_wage,
        };
        const response = await employeeServices.addEmpToStaff(payload);
        if (response) {
          toast.success("Uğurla əlavə edildi!");
          onClose();
          getStructure();
        } else {
          toast.error(
            "Hər hansı bir xəta baş verdi. Zəhmət olmasa, inspektdən network hissəni açın!"
          );
        }
      } else {
        const newStaffId = selectedStaff?.staff_id;
        const newSelectedEmp = selected_emp._id;
        const response = await employeeServices.updateEmployeeById(
          newStaffId,
          newSelectedEmp,
          // selected_wage
        );
        if (response) {
          toast.success("Uğurla əlavə edildi!");
          onClose();
          getStructure();
        } else {
          toast.error(
            "Hər hansı bir xəta baş verdi. Zəhmət olmasa, inspektdən network hissəni açın!"
          );
        }
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error("Server xətası baş verdi!");
    }
  };

  const generateContract = async () => {
    try {
      const payload = {
        docModel: "Employee",
        subjects: [selected_emp._id],
        data: {
          staffId: selectedStaff?.staff_id,
          structure: structureById._id,
          position: selectedStaff?.position_id,
          jobStartDate: moment().format("MM/DD/YYYY"),
          agreementDate: moment().format("MM/DD/YYYY"),
        },
      };

      const response = await employeeServices.generateContract(payload);
      if (response) {
        setConfirmButtonActive(true);
        setDownloadId(response._id);
        toast.success("Ərizə uğurla tərtib edildi!");
      } else {
        setConfirmButtonActive(false);
        toast.error("Hər hansı bir xəta baş verdi!");
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error("Server xətası baş verdi!");
    }
  };

  // let exact_wage = one_employee?.supplementalWages?.find(
  //   (w) => w?.name === selectedStaff?.supplemental_wage?.name
  // )?.value;

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setSelectedEmp("");
        setConfirmButtonActive(false);
      }}
      PaperProps={{ style: { minWidth: "600px" } }}
      sx={{ minWidth: '600px' }}
      aria-labelledby="form-dialog-title"
      style={{ backgroundColor: "#ffffff21", minWidth: "650px !important" }}
    >
      <DialogTitle
        id="form-dialog-title"
        className={`${classes.fs_23} ${classes.mb_2} text-center`}
      >
        Əməkdaş təyin edin
      </DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-lg-12 mt-4" style={{ color: "#333" }}>
            <Autocomplete
              options={all_employee.options}
              onChange={onSaveEmployeeId}
              renderInput={(params) => (
                <TextField {...params} label="Əməkdaşı seçin" />
              )}
              getOptionLabel={(option) => option.label}
              inputValue={searchValue}
              onInputChange={(event, newInputValue) =>
                setSearchValue(newInputValue)
              }
              renderOption={(props, option) => (
                <li {...props} key={option.key} style={{ width: "100%" }}>
                  {option.label}
                </li>
              )}
            // PaperComponent={(props) => <CustomPaper {...props} />}
            />
          </div>
          {structureById?.isVerified && (
            <div className="col-12 mt-5">
              <Button
                variant="contained"
                color="success"
                disabled={!selected_emp}
                onClick={generateContract}
              >
                <span className={selected_emp ? "text-white" : "text-disabled"}>
                  Ərizəni Tərtib et
                </span>
              </Button>
            </div>
          )}
          {/* <div>
            {Object.keys(one_employee || {}).length > 0 &&
              one_employee?.supplementalWages?.find(
                (w) => w?.name === selectedStaff?.supplemental_wage?.name
              ) && (
                <>
                  <GENERAL_BLOCK clr="#ff6666" className="mt-3">
                    Seçilmiş əməkdaşın təyin olunacağı ştatda və əməkdaşın
                    məlumatlarında fərqli dəyərlərdə eyni "
                    <b>{selectedStaff?.supplemental_wage?.name}"</b> səbəbli
                    əmək haqqı əlavəsi mövcuddur. Zəhmət olmasa aşağıdakı
                    məbləğlərdən birini seçin!
                  </GENERAL_BLOCK>
                  <div className="d-flex mt-3">
                    <ToggleButtonGroup
                      fullWidth
                      color="primary"
                      value={selected_wage}
                      exclusive
                      onChange={(_, val) => set_selected_wage(val)}
                      aria-label="Platform"
                    >
                      <ToggleButton value={exact_wage}>
                        {exact_wage} &#8380;
                      </ToggleButton>
                      <ToggleButton
                        value={selectedStaff?.supplemental_wage?.value}
                      >
                        {selectedStaff?.supplemental_wage?.value} &#8380;
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </>
              )}
          </div> */}
          <div className="col-12 d-flex justify-content-end align-items-center mt-5">
            {structureById?.isVerified ? (
              <>
                <a
                  href={`${BASE_URL}/employee/contract/${downloadId}/download`}
                  download
                  style={{
                    pointerEvents: downloadId !== "" ? "auto" : "none",
                    marginRight: "8px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={downloadId === ""}
                  >
                    Ərizəyə bax
                  </Button>
                </a>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!confirmButtonActive}
                  onClick={addEmployeeToStaff}
                >
                  <span className="text-disabled">Təsdiqlə</span>
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!selected_emp}
                  // disabled={!selected_emp || (exact_wage && !selected_wage)}
                  onClick={addEmployeeToStaff}
                >
                  <span className="text-disabled">Ştata əlavə et</span>
                </Button>
              </>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EmployeeDialog;
