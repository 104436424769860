import DeleteIcon from "@mui/icons-material/Delete";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
// import { renderErrorMessage } from "./GraphicValidation";
import ruLocale from "date-fns/locale/ru";
import React from "react";
import styles from "../../GraphicForm/GraphicForm.module.css";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const GraphicFormRight = ({
  label,
  formData,
  isHoliday,
  numGroups,
  inputSets,
  showInputs,
  scheduleType,
  handleChange,
  handleReduceInputs,
  handleChangeCheckBox,
}) => {
  let arrayToIterate = [];
  if (scheduleType === "shift") {
    if (label === "2 növbəli") {
      numGroups = 2;
    } else if (label === "3 növbəli") {
      numGroups = 3;
    } else if (label === "4 növbəli") {
      numGroups = 4;
    }
    arrayToIterate = [...Array(numGroups)];
  } else {
    arrayToIterate = [...Array(inputSets)];
  }
  const handleDeleteInput = (index) => {
    const updatedFormData = { ...formData };
    updatedFormData[`jobStartTime${index}`] = "";
    updatedFormData[`jobEndTime${index}`] = "";
    handleReduceInputs(index, updatedFormData);
  };
  return (
    <>
      {scheduleType !== "" && (
        <div className={styles.graphicFormRight}>
          {arrayToIterate.map(
            (_, index) =>
              showInputs && (
                <div key={index} className={styles.graphicFormRightMain}>
                  <div className={styles.topInputGroup}>
                    {scheduleType === "shift" && (
                      <label htmlFor="">{`${index + 1} - ${
                        index === 0 || index === 1 ? "ci" : "cü"
                      } növbə`}</label>
                    )}
                    <div className={styles.inptGroup}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ruLocale}
                      >
                        <TimePicker
                          label="Başlama saatı"
                          value={
                            scheduleType === "standard"
                              ? formData.jobStartTime || null
                              : formData[`jobStartTime${index}`] || null
                          }
                          onChange={(newValue) => {
                            handleChange({
                              target: {
                                name:
                                  scheduleType === "standard"
                                    ? "jobStartTime"
                                    : `jobStartTime${index}`,
                                value: newValue || "",
                              },
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              disabled={isHoliday[index]}
                              className={styles.inpt}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {/* <>
                      <TextField
                        required
                        type="time"
                        disabled={isHoliday[index]}
                        label="Başlama saatı"
                        className={styles.inpt}
                        onChange={handleChange}
                        id={
                          scheduleType === "standard"
                            ? "jobStartTime"
                            : `jobStartTime${index}`
                        }
                        name={
                          scheduleType === "standard"
                            ? "jobStartTime"
                            : `jobStartTime${index}`
                        }
                        value={
                          scheduleType === "standard"
                            ? formData.jobStartTime
                            : formData[`jobStartTime${index}`]
                        }
                        // error={renderErrorMessage(
                        //   index,
                        //   "jobStartTime",
                        //   formData,
                        //   scheduleType,
                        //   arrayToIterate
                        // )}
                        // helperText={renderErrorMessage(
                        //   index,
                        //   "jobStartTime",
                        //   formData,
                        //   scheduleType,
                        //   arrayToIterate
                        // )}
                      />
                    </> */}
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ruLocale}
                      >
                        <TimePicker
                          label="Bitmə saatı"
                          value={
                            scheduleType === "standard"
                              ? formData.jobEndTime || null
                              : formData[`jobEndTime${index}`] || null
                          }
                          onChange={(newValue) => {
                            handleChange({
                              target: {
                                name:
                                  scheduleType === "standard"
                                    ? "jobEndTime"
                                    : `jobEndTime${index}`,
                                value: newValue || "",
                              },
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              disabled={isHoliday[index]}
                              className={styles.inpt}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {/* <>
                      <TextField
                        required
                        type="time"
                        disabled={isHoliday[index]}
                        label="Bitmə saatı"
                        className={styles.inpt}
                        onChange={handleChange}
                        id={
                          scheduleType === "standard"
                            ? "jobEndTime"
                            : `jobEndTime${index}`
                        }
                        name={
                          scheduleType === "standard"
                            ? "jobEndTime"
                            : `jobEndTime${index}`
                        }
                        value={
                          scheduleType === "standard"
                            ? formData.jobEndTime
                            : formData[`jobEndTime${index}`]
                        }
                        // error={renderErrorMessage(
                        //   index,
                        //   "jobEndTime",
                        //   formData,
                        //   scheduleType,
                        //   arrayToIterate
                        // )}
                        // helperText={renderErrorMessage(
                        //   index,
                        //   "jobEndTime",
                        //   formData,
                        //   scheduleType,
                        //   arrayToIterate
                        // )}
                      />
                    </> */}
                    </div>
                    {scheduleType === "flexible" && (
                      <div className={styles.CheckboxBottom}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={formData.isHoliday}
                                onChange={() => handleChangeCheckBox(index)}
                              />
                            }
                            label="İstirahət günü"
                          />
                        </FormGroup>
                        {inputSets > 1 && index > 0 ? (
                          <DeleteIcon
                            onClick={() => handleDeleteInput(index)}
                            className={styles.deleteBtn}
                          />
                        ) : null}
                      </div>
                    )}
                  </div>
                  <div className={styles.inptGroup}>
                    {scheduleType === "standard" && (
                      <>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={ruLocale}
                        >
                          <TimePicker
                            label="Nahar saatı başlama"
                            value={formData.restStartTime || null}
                            onChange={(newValue) => {
                              handleChange({
                                target: {
                                  name: "restStartTime",
                                  value: newValue || "",
                                },
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                // disabled={isHoliday[index]}
                                className={styles.inpt}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {/* <>
                        <TextField
                          className={styles.inpt}
                          required
                          type="time"
                          name="restStartTime"
                          id="restStartTime"
                          label="Nahar saatı başlama"
                          value={formData.restStartTime}
                          onChange={handleChange}
                          // helperText={renderErrorMessage(
                          //   index,
                          //   "restStartTime",
                          //   formData,
                          //   scheduleType,
                          //   arrayToIterate
                          // )}
                          // error={renderErrorMessage(
                          //   index,
                          //   "restStartTime",
                          //   formData,
                          //   scheduleType,
                          //   arrayToIterate
                          // )}
                        />
                      </> */}
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={ruLocale}
                        >
                          <TimePicker
                            label="Nahar saatı bitmə"
                            value={formData.restEndTime || null}
                            onChange={(newValue) => {
                              handleChange({
                                target: {
                                  name: "restEndTime",
                                  value: newValue || "",
                                },
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                // disabled={isHoliday[index]}
                                className={styles.inpt}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {/* <>
                        <TextField
                          className={styles.inpt}
                          required
                          type="time"
                          name="restEndTime"
                          id="restEndTime"
                          label="Nahar saatı bitmə"
                          value={formData.restEndTime}
                          onChange={handleChange}
                          // helperText={renderErrorMessage(
                          //   index,
                          //   "restEndTime",
                          //   formData,
                          //   scheduleType,
                          //   arrayToIterate
                          // )}
                          // error={renderErrorMessage(
                          //   index,
                          //   "restEndTime",
                          //   formData,
                          //   scheduleType,
                          //   arrayToIterate
                          // )}
                        />
                      </> */}
                      </>
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      )}
    </>
  );
};

export default GraphicFormRight;
