import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Column from "../../models/table/Column";
import { OPERATORS_CONTAINING } from "../../constants";
import BusinessTripForm from "./BusinessTripForm";
import { getBusinessTrip } from "./services";
import moment from "moment";
import { getStaticFilterValues } from "../../utils/forFilterDropdown/getStructureUtils";

const BusinessTrip = () => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [total, setTotal] = useState();

  const getBusinessTripData = async () => {
    try {
      const res = await getBusinessTrip(limit, offset);
      setData(res?.data?.data);
      setTotal(res?.data?.total);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getBusinessTripData();
  }, [filters, offset, limit]);

  const column = [
    new Column({
      path: "employee.tableNumber",
      headerName: "Tabel Nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "contract.data.docNumber",
      headerName: "Ə.M. Müqaviləsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "baseStructure.organization.name",
      headerName: "Struktur",
      filterType: OPERATORS_CONTAINING.IN,
      filterColumnKey: "baseStructure",
      fetchFilterValuesV2: getStaticFilterValues,
      keyForResponseDataFromFilter: "baseStructures",
    }),
    new Column({
      path: "subStructure.source.name",
      headerName: "Sub-struktur",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "position.source.name",
      headerName: "Vəzifə",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substituteEmployee.tableNumber",
      headerName: "Əvəz Edənin Tabel Nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substituteEmployee.firstname",
      headerName: "Əvəz Edənin Adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substituteEmployee.lastname",
      headerName: "Əvəz Edənin Soyadı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substituteEmployee.fathername",
      headerName: "Əvəz Edənin Atasının Adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substituteContract.data.docNumber",
      headerName: "Əvəz Edənin Ə.M. Müqaviləsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substituteBaseStructure.organization.name",
      headerName: "Əvəz Edənin işlədiyi Struktur",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substituteSubStructure.source.name",
      headerName: "Əvəz Edənin işlədiyi Sub-struktur",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substitutePosition.source.name",
      headerName: "Əvəz Edənin Vəzifəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "tripReason",
      headerName: "Ezamiyyət Səbəbi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.substitutePosition",
      headerName: "Ölkədaxili / Xarici",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row, column) =>
        row?.isForeignCountry ? (
          <div className="forCenter">Xarici</div>
        ) : (
          <div className="forCenter">Ölkədaxili</div>
        ),
    }),
    new Column({
      path: "employee.substitutePosition",
      headerName: "Ezam Olduğu Yer",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row, column) => {
        if (row?.foreignTripDetails.length > 0) {
          return (
            <>
              {row.foreignTripDetails.map((item, index) => {
                const city =
                  item?.cities?.length > 0 ? item?.cities?.[0]?.name : null;
                return (
                  <div
                    className="forCenter"
                    style={{ flexDirection: "column" }}
                    key={index}
                  >
                    <p style={{ textAlign: "center" }}>{item.name}</p>
                    {city && <p style={{ textAlign: "center" }}>{city}</p>}
                  </div>
                );
              })}
            </>
          );
        }
        if (row?.domesticTripDetails.length > 0) {
          const city = row?.domesticTripDetails?.[0]?.location;
          return <div className="forCenter">{city}</div>;
        }
        return null;
      },
    }),
    new Column({
      path: "tripStartDate",
      headerName: "Ezamiyyə Başalama Tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      renderCell: (row, column) =>
        row?.tripStartDate ? (
          <div className="forCenter">
            {moment(row?.tripStartDate).format("DD/MM/YYYY")}
          </div>
        ) : null,
    }),
    new Column({
      path: "tripEndDate",
      headerName: "Ezamiyyə Bitmə Tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      renderCell: (row, column) =>
        row?.tripEndDate ? (
          <div className="forCenter">
            {moment(row?.tripEndDate).format("DD/MM/YYYY")}
          </div>
        ) : null,
    }),
    new Column({
      path: "returnToWorkDate",
      headerName: "İşə çıxma Tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      renderCell: (row, column) =>
        row?.returnToWorkDate ? (
          <div className="forCenter">
            {moment(row?.returnToWorkDate).format("DD/MM/YYYY")}
          </div>
        ) : null,
    }),
    new Column({
      path: "tripDuration",
      headerName: "Ezamiyyə müddəti (gün)",
      filterType: OPERATORS_CONTAINING.NUMBER,
    }),
    new Column({
      path: "employee.substitutePosition",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      // isStaticColumn: true,
      widthOfColumn: 200,
      // renderCell: operatorGeneratorHandler,
    }),
  ];
  return (
    <div className="business_trip_main">
      <Typography variant="h3" gutterBottom display="inline">
        Ezamiyyətlər
      </Typography>
      <BusinessTripForm />
      <Table
        data={data}
        limit={limit}
        offset={offset}
        totalRows={total}
        columns={column}
        isLoading={loading}
        setLimit={(value) => setLimit(value)}
        setOffset={(value) => setOffset(value)}
        cancelAppliedFilters={() => setFilters([])}
        applyFiltersHandler={(filters) => setFilters(filters)}
        // downloadHandler={(cols) => downloadHandler(cols)}
      />
    </div>
  );
};

export default BusinessTrip;
