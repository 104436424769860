import {
  AccessibilityNew,
  AccountTree,
  Assessment,
  AssignmentTurnedIn,
  AttachMoney,
  CalendarMonth,
  ContactPageOutlined,
  FilePresent,
  Group,
  Moving,
  QuestionAnswer,
  Settings,
  SupportAgent,
} from "@mui/icons-material";
import { Home } from "react-feather";

// const pagesSection = [
//   {
//     href: "/dashboard",
//     icon: Sliders,
//     title: "Dashboard",
//     children: [
//       {
//         href: "/dashboard/default",
//         title: "Default",
//       },
//       {
//         href: "/dashboard/analytics",
//         title: "Analytics",
//       },
//       {
//         href: "/dashboard/saas",
//         title: "SaaS",
//       },
//     ],
//   },
//   {
//     href: "/",
//     icon: SettingsApplicationsOutlinedIcon,
//     title: "Məlumat Kataloqları",
//     children: [
//       {
//         href: "/operations/structure-details",
//         title: "Təşkilatın məlumatları",
//       },
//       {
//         href: "/operations/employer-details",
//         title: "İşəgötürənin məlumatları",
//       },
//       {
//         href: "/operations/structure-naming",
//         title: "Struktur adlandırmaları",
//       },
//       {
//         href: "/operations/data-table",
//         title: "Məlumatlar cədvəli",
//       },
//       {
//         href: "/operations/management",
//         title: "Rəhbərlik",
//       },
//     ],
//   },
//   {
//     href: "/",
//     icon: DocumentScannerOutlinedIcon,
//     title: "Sənədləşmə",
//     children: [
//       {
//         href: "/documentation/commands",
//         title: "Əmrlər",
//       },
//       {
//         href: "/documentation/contracts",
//         title: "Müqavilələr",
//       },
//       {
//         href: "/documentation/application",
//         title: "Ərizələr",
//       },
//       {
//         href: "/documentation/template/0",
//         title: "Şablonlar",
//       },
//     ],
//   },
//   {
//     href: "/signing",
//     title: "Sənədlərin imzalanması sorğuları",
//     icon: TaskIcon,
//   },
//   {
//     href: "/",
//     icon: AccountBoxOutlined,
//     title: "Əməkdaşlar",
//     children: [
//       {
//         href: "/employees",
//         title: "Əməkdaşlar",
//       },
//       {
//         href: "/employees/change-requests",
//         title: "Dəyişiklik sorğuları",
//       },
//       // {
//       //   href: "/operations/company-details",
//       //   title: "Təşkilatın məlumatları",
//       // },
//     ],
//   },
//   {
//     href: "/leaves",
//     icon: Close,
//     title: "İşə çıxmama",
//     children: [
//       {
//         href: "/leaves/removal",
//         title: "Kənarlaşdırma",
//       },
//       {
//         href: "/leaves/bulletin",
//         title: "Bülleten",
//       },
//       {
//         href: "/leaves/unknown-reason",
//         title: "Naməlum səbəblər",
//       },
//     ],
//   },
//   {
//     href: "/employment-contracts",
//     icon: Grading,
//     title: "Əmək müqavilələri",
//   },
//   {
//     href: "/decision-chain",
//     icon: LinkIcon,
//     title: "Təsdiqləmə matrisi",
//   },
//   {
//     href: "/approver-vacation-requests",
//     title: "Sistem təsdiqləri",
//     icon: DoneAll,
//   },
//   {
//     href: "/appointment-requests",
//     title: "Təyinatların izlənməsi",
//     icon: CallSplit,
//   },
//   {
//     href: "/",
//     icon: AttachEmailIcon,
//     title: "Məzuniyyət",
//     children: [
//       {
//         href: "/hr-vacation-requests",
//         title: "HR Məzuniyyət sorğuları",
//       },
//       {
//         href: "/emp-vacation-requests",
//         title: "Əməkdaş Məzuniyyət sorğuları",
//       },
//       // {
//       //   href: "/approver-vacation-requests",
//       //   title: "Menecer Məzuniyyət sorğuları",
//       // },
//     ],
//   },
//   {
//     href: "/company-structure",
//     icon: BadgeOutlinedIcon,
//     title: "Təşkilatı struktur",
//   },
//   {
//     href: "/chat",
//     icon: ChatBubbleOutlineIcon,
//     title: "Canlı çat",
//   },
//   // {
//   //   href: "/pages",
//   //   icon: Layout,
//   //   title: "Pages",
//   //   children: [
//   //     {
//   //       href: "/pages/profile",
//   //       title: "Profile",
//   //     },
//   //     {
//   //       href: "/pages/settings",
//   //       title: "Settings",
//   //     },
//   //     {
//   //       href: "/pages/pricing",
//   //       title: "Pricing",
//   //     },
//   //     {
//   //       href: "/pages/chat",
//   //       title: "Chat",
//   //     },
//   //     {
//   //       href: "/pages/blank",
//   //       title: "Blank Page",
//   //     },
//   //   ],
//   // },
//   // {
//   //   href: "/projects",
//   //   icon: Briefcase,
//   //   title: "Projects",
//   //   badge: "8",
//   // },
//   // {
//   //   href: "/orders",
//   //   icon: ShoppingCart,
//   //   title: "Orders",
//   // },
//   // {
//   //   href: "/invoices",
//   //   icon: CreditCard,
//   //   title: "Invoices",
//   //   children: [
//   //     {
//   //       href: "/invoices",
//   //       title: "List",
//   //     },
//   //     {
//   //       href: "/invoices/detail",
//   //       title: "Detail",
//   //     },
//   //   ],
//   // },
//   {
//     href: "/tasks",
//     icon: CheckSquare,
//     title: "Tasks",
//     badge: "17",
//   },
//   {
//     href: "/calendar",
//     icon: Calendar,
//     title: "Calendar",
//   },
//   // {
//   //   href: "/auth",
//   //   icon: Users,
//   //   title: "Auth",
//   //   children: [
//   //     {
//   //       href: "/auth/sign-in",
//   //       title: "Sign In",
//   //     },
//   //     {
//   //       href: "/auth/sign-up",
//   //       title: "Sign Up",
//   //     },
//   //     {
//   //       href: "/auth/reset-password",
//   //       title: "Reset Password",
//   //     },
//   //     {
//   //       href: "/auth/404",
//   //       title: "404 Page",
//   //     },
//   //     {
//   //       href: "/auth/500",
//   //       title: "500 Page",
//   //     },
//   //   ],
//   // },
//   {
//     href: "/ticketing",
//     icon: HelpIcon,
//     title: "Dəstək xidməti",
//   },
// ];

const pagesSection = [
  {
    href: "/private",
    icon: Home,
    title: "Ana səhifə",
  },
  {
    href: "/",
    icon: Settings,
    title: "Sazlamalar",
    children: [
      {
        href: "/",
        // icon: SettingsApplicationsOutlinedIcon,
        title: "Məlumat Kataloqları",
        children: [
          {
            href: "/operations/structure-details",
            title: "Təşkilatın məlumatları",
          },
          {
            href: "/operations/employer-details",
            title: "İşəgötürənin məlumatları",
          },
          {
            href: "/operations/structure-naming",
            title: "Struktur adlandırmaları",
          },
          {
            href: "/operations/data-table",
            title: "Məlumatlar cədvəli",
          },
          // {
          //   href: "/operations/management",
          //   title: "Rəhbərlik",
          // },
        ],
      },

      {
        href: "/",
        title: "İstifadəçilər",
      },
      {
        href: "/",
        title: "Rollar və səlahiyyətlər",
      },
      {
        href: "/",
        title: "Məlumat silinmələri",
      },
    ],
  },
  {
    href: "/",
    title: "Təşkilati Struktur",
    icon: AccountTree,
    children: [
      {
        href: "/company-structure",
        title: "Struktur",
      },
      {
        href: "/structure-requests",
        title: "Struktur dəyişikliyi sorğuları",
      },
      {
        href: "/operations/ts-design-settings",
        title: "TS Dizayn sazlamaları",
      },
    ],
  },
  {
    href: "/",
    title: "Biznes proseslərin izlənməsi",
    icon: Moving,
    children: [
      {
        href: "/",
        title: "Məzuniyyət BP",
        children: [
          {
            href: "/hr-vacation-requests",
            title: "Ümumi məzuniyyətlər",
          },
          {
            href: "/vacation-tracking",
            title: "Əmək məzuniyyətinin izlənməsi",
          },
          {
            href: "/vacation-schedule",
            title: "Məzuniyyət qrafiki",
          },
          // {
          //   href: "/",
          //   title: "Qalıq məzuniyyətlərin daxil edilməsi",
          // },
        ],
      },
      {
        href: "/appointment-requests",
        title: "Təyinatlar BP",
      },
      { href: "/signing", title: "Sənədlərin imzalanması BP" },
      { href: "/approver-vacation-requests", title: "Sistem təsdiqləri" },
      {
        href: "/decision-chain",
        title: "Təsdiqləmə matrisi",
      },
      {
        href: "/leaves/removal",
        title: "İşdən kənarlaşdırma BP",
      },
      {
        href: "/change-requests",
        title: "Əməkdaşların məlumat dəyişikliyi sorğuları",
      },
      {
        href: "/employment-contracts-changes",
        title: "Əmək müqaviləsinə dəyişiklik",
      },
    ],
  },
  {
    href: "/",
    title: "Əməkdaşlar",
    icon: Group,
    children: [
      {
        href: "/employees",
        title: "Əməkdaşların siyahısı",
      },
    ],
  },
  {
    href: "/",
    icon: FilePresent,
    title: "Sənədləşmə",
    children: [
      {
        href: "/documentation/orders",
        title: "Əmrlər",
      },
      {
        href: "/documentation/contracts",
        title: "Müqavilələr",
      },
      {
        href: "/documentation/application",
        title: "Ərizələr",
      },
      {
        href: "/documentation/template/0",
        title: "Şablonlar",
      },
    ],
  },
  {
    href: "/",
    title: "Əmək müqavilələri",
    icon: ContactPageOutlined,
    children: [
      {
        href: "/employment-contracts",
        title: "Əmək müqavilələri reyesteri",
      },
    ],
  },
  {
    href: "/",
    title: "Əməyin təşkili",
    icon: AccessibilityNew,
    children: [
      {
        href: "/",
        title: "İşə çıxmama",
        children: [
          {
            href: "/leaves/bulletin",
            title: "ƏQMİ - xəstəlik vərəqələri",
          },

          {
            href: "/leaves/unknown-reason",
            title: "Naməlum səbəbdən işə çıxmama",
          },
          {
            href: "/leaves/dismissal",
            title: "İşdən Kənarlaşdırmalar",
          },
          {
            href: "/leaves/businessTrip",
            title: "Ezamiyyətlər",
          },
        ],
      },
      {
        href: "/",
        title: "Qrafik məlumatları",
        children: [
          {
            href: "/work-hours-graphic",
            title: "İş vaxtının qrafiki",
          },
          {
            href: "/graphic-settings",
            title: "Qrafik sazlamaları",
          },
        ],
      },
      {
        href: "/work-hour-account-table",
        title: "İş vaxtının uçotu tabeli",
      },
      {
        href: "/production-calendar",
        title: "İstehsalat təqvimi və norma saatları",
      },
      {
        href: "/",
        title: "İşə davamiyyət",
      },
    ],
  },
  {
    href: "/",
    title: "Əməkhaqqları",
    icon: AttachMoney,
    children: [
      {
        href: "/salaries/components",
        title: "Komponentlər",
      },
      {
        href: "/salaries/fixedcomponents",
        title: "Sabit Komponentlər",
      },
      {
        href: "/salaries/employeecomponents",
        title: "Əməkdaşın Komponentləri",
      },
      {
        href: "/salaries/salarycalculation",
        title: "Ə/H hesablanması",
      },
      {
        href: "/salaries/salarystatement",
        title: "Əməkhaqqı çıxarışı",
      },
      {
        href: "/salaries/salaryreports",
        title: "Hesabatlar",
      },
    ],
  },
  {
    href: "/",
    title: "Hesabatlar",
    icon: Assessment,
  },
  {
    href: "/chat",
    title: "Daxili kommunikasiya",
    icon: QuestionAnswer,
  },
  {
    href: "/tasks",
    title: "Tapşırıqların idarə olunması",
    icon: AssignmentTurnedIn,
  },
  {
    href: "/calendar",
    title: "Təqvim",
    icon: CalendarMonth,
  },
  {
    href: "/ticketing",
    title: "Dəstək xidməti",
    icon: SupportAgent,
  },
];

// const elementsSection = [
//   {
//     href: "/components",
//     icon: Grid,
//     title: "Components",
//     children: [
//       {
//         href: "/components/alerts",
//         title: "Alerts",
//       },
//       {
//         href: "/components/accordion",
//         title: "Accordion",
//       },
//       {
//         href: "/components/avatars",
//         title: "Avatars",
//       },
//       {
//         href: "/components/badges",
//         title: "Badges",
//       },
//       {
//         href: "/components/buttons",
//         title: "Buttons",
//       },
//       {
//         href: "/components/cards",
//         title: "Cards",
//       },
//       {
//         href: "/components/chips",
//         title: "Chips",
//       },
//       {
//         href: "/components/dialogs",
//         title: "Dialogs",
//       },
//       {
//         href: "/components/lists",
//         title: "Lists",
//       },
//       {
//         href: "/components/menus",
//         title: "Menus",
//       },
//       {
//         href: "/components/pagination",
//         title: "Pagination",
//       },
//       {
//         href: "/components/progress",
//         title: "Progress",
//       },
//       {
//         href: "/components/snackbars",
//         title: "Snackbars",
//       },
//       {
//         href: "/components/tooltips",
//         title: "Tooltips",
//       },
//     ],
//   },
//   {
//     href: "/charts",
//     icon: PieChart,
//     title: "Charts",
//     children: [
//       {
//         href: "/charts/chartjs",
//         title: "Chart.js",
//       },
//       {
//         href: "/charts/apexcharts",
//         title: "ApexCharts",
//       },
//     ],
//   },
//   {
//     href: "/forms",
//     icon: CheckSquare,
//     title: "Forms",
//     children: [
//       {
//         href: "/forms/pickers",
//         title: "Pickers",
//       },
//       {
//         href: "/forms/selection-controls",
//         title: "Selection Controls",
//       },
//       {
//         href: "/forms/selects",
//         title: "Selects",
//       },
//       {
//         href: "/forms/text-fields",
//         title: "Text Fields",
//       },
//       {
//         href: "/forms/editors",
//         title: "Editors",
//       },
//       {
//         href: "/forms/formik",
//         title: "Formik",
//       },
//     ],
//   },
//   {
//     href: "/tables",
//     icon: List,
//     title: "Tables",
//     children: [
//       {
//         href: "/tables/simple-table",
//         title: "Simple Table",
//       },
//       {
//         href: "/tables/advanced-table",
//         title: "Advanced Table",
//       },
//       {
//         href: "/tables/data-grid",
//         title: "Data Grid",
//       },
//     ],
//   },
//   {
//     href: "/icons",
//     icon: Heart,
//     title: "Icons",
//     children: [
//       {
//         href: "/icons/material-icons",
//         title: "Material Icons",
//       },
//       {
//         href: "/icons/feather-icons",
//         title: "Feather Icons",
//       },
//     ],
//   },
//   {
//     href: "/maps",
//     icon: Map,
//     title: "Maps",
//     children: [
//       {
//         href: "/maps/google-maps",
//         title: "Google Maps",
//       },
//       {
//         href: "/maps/vector-maps",
//         title: "Vector Maps",
//       },
//     ],
//   },
// ];

// const docsSection = [
//   {
//     href: "/documentation/welcome",
//     icon: BookOpen,
//     title: "Documentation",
//   },
//   {
//     href: "/changelog",
//     icon: List,
//     title: "Changelog",
//     badge: "v4.2.0",
//   },
// ];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
  // {
  //   title: "Elements",
  //   pages: elementsSection,
  // },
  // {
  //   title: "Mira Pro",
  //   pages: docsSection,
  // },
];

export default navItems;
