import { RemoveRedEye, Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// CSS
import style from "../documentRegister.module.css";

//custom components
import Header from "../../../components/layouts/header/Header";
import { getDocuments } from "../../../services/documentations/ContractServices";
import { Field } from "formik";

export default function Orders() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [staticOrders, setStaticOrders] = useState([]);
  const [filteredStaticOrders, setFilteredStaticOrders] = useState([]);
  const [dynamicOrders, setDynamicOrders] = useState([]);
  const [filteredDyanmicOrders, setFilteredDynamicOrders] = useState([]);

  useEffect(() => {
    const getDocs = async () => {
      try {
        const { data } = await getDocuments("order");
        setStaticOrders(data.static.records);
        setFilteredStaticOrders(data.static.records);
        setDynamicOrders(data.dynamic.records);
        setFilteredDynamicOrders(data.dynamic.records);
        setOrders(data);
      } catch (error) {}
    };
    getDocs();
  }, []);

  const headerData = {
    title: "Əmrlərin məlumatları",
    variant: "h3",
  };

  const search = (data) => {
    const newStatic = staticOrders.filter((item) => {
      return item.label.toLowerCase().startsWith(data.toLowerCase());
    });
    setFilteredStaticOrders(newStatic);
    const newDynamic = dynamicOrders.filter((item) => {
      return item.label.toLowerCase().startsWith(data.toLowerCase());
    });
    setFilteredDynamicOrders(newDynamic);
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Header data={headerData} />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/documentation/orders/orders-register")}
          >
            Əmrlərin reyestri
          </Button>
        </Grid>
      </Grid>
      <Divider
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
      />

      <TextField
        variant="outlined"
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        onChange={(event) => {
          search(event.target.value);
        }}
      />
      <Grid
        sx={{
          marginBottom: "30px",
          textAlign: "center",
        }}
      >
        <Header data={{ title: "Statik əmr növləri", variant: "h4" }} />
      </Grid>
      {!filteredStaticOrders.length ? (
        <Grid
          sx={{
            width: "100%",
            fontSize: "30px",
            fontWeight: 600,
            textAlign: "center",
            marginTop: "20px",
            backgroundColor: "#233044",
            borderRadius: "10px",
          }}
        >
          Heç bir məlumat tapılmadı
        </Grid>
      ) : (
        <></>
      )}

      <Grid className={style.orderList}>
        {filteredStaticOrders?.map((item, index) => {
          return (
            <React.Fragment key={`${item} ${index}`}>
              <Grid className={style.list} sx={{ transition: "0.3s" }}>
                <Grid className={style.infoSide}>
                  <Tooltip title={item.info}>
                    <IconButton
                      sx={{
                        padding: 0,
                      }}
                    >
                      <div className={style.info}>i</div>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid className={style.listLeftSide}>
                  {/* <img className={style.icon} src={employeePhotoAsBase64} /> */}
                  <Grid className={style.docType}>{item.label}</Grid>
                </Grid>

                <Grid className={style.listRightSide}>
                  <Grid className={style.statistics}>
                    <Grid>
                      <Grid>{item.statistics.total}</Grid>
                      <Grid className={style.statisticType}>Cəm</Grid>
                    </Grid>
                    <div style={{ border: "1px solid black" }}></div>
                    <Grid>
                      <Grid>{item.statistics.pending}</Grid>
                      <Grid className={style.statisticType}>Gözləyən</Grid>
                    </Grid>
                    <Grid>
                      <Grid>{item.statistics.approved}</Grid>
                      <Grid className={style.statisticType}>İmzalanmış</Grid>
                    </Grid>

                    <Grid>
                      <Grid>{item.statistics.rejected}</Grid>
                      <Grid className={style.statisticType}>
                        İmtina edilmiş
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={style.buttons}>
                    <Tooltip title="Əmr yarat">
                      {item.isContractCreatable ? (
                        <button
                          className={style.button}
                          onClick={() =>
                            navigate(
                              `/documentation/command/create?value=${item.id}&label=${item.label}`
                            )
                          }
                        >
                          <AddIcon />
                        </button>
                      ) : (
                        <></>
                      )}
                    </Tooltip>
                    {item.statistics.total ? (
                      <Tooltip title="Əmrlərə bax">
                        <button
                          className={style.button}
                          onClick={() =>
                            navigate(
                              `/documentation/contract/viewtypeofcontracts?contractID=${item.id}&contractType=${item.type}`
                            )
                          }
                        >
                          <RemoveRedEye />
                        </button>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      <Grid
        sx={{
          marginBottom: "30px",
          marginTop: "60px",
          textAlign: "center",
        }}
      >
        <Header data={{ title: "Dinamik əmr növləri", variant: "h4" }} />
      </Grid>
      {!filteredDyanmicOrders.length ? (
        <Grid
          sx={{
            width: "100%",
            fontSize: "30px",
            fontWeight: 600,
            textAlign: "center",
            marginTop: "20px",
            backgroundColor: "#233044",
            borderRadius: "10px",
          }}
        >
          Heç bir məlumat tapılmadı
        </Grid>
      ) : (
        <></>
      )}

      <Grid className={style.orderList}>
        {filteredDyanmicOrders?.map((item, index) => {
          return (
            <React.Fragment key={`${item} ${index}`}>
              <Grid className={style.list} sx={{ transition: "0.3s" }}>
                <Grid className={style.infoSide}>
                  <Tooltip title={item.info}>
                    <IconButton
                      sx={{
                        padding: 0,
                      }}
                    >
                      <div className={style.info}>i</div>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid className={style.listLeftSide}>
                  {/* <img className={style.icon} src={employeePhotoAsBase64} /> */}
                  <Grid className={style.docType}>{item.label}</Grid>
                </Grid>

                <Grid className={style.listRightSide}>
                  <Grid className={style.statistics}>
                    <Grid>
                      <Grid>{item.statistics.total}</Grid>
                      <Grid className={style.statisticType}>Cəm</Grid>
                    </Grid>
                    <div style={{ border: "1px solid black" }}></div>
                    <Grid>
                      <Grid>{item.statistics.pending}</Grid>
                      <Grid className={style.statisticType}>Gözləyən</Grid>
                    </Grid>
                    <Grid>
                      <Grid>{item.statistics.approved}</Grid>
                      <Grid className={style.statisticType}>İmzalanmış</Grid>
                    </Grid>

                    <Grid>
                      <Grid>{item.statistics.rejected}</Grid>
                      <Grid className={style.statisticType}>
                        İmtina edilmiş
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={style.buttons}>
                    <Tooltip title="Əmr yarat">
                      {item.isContractCreatable ? (
                        <button
                          className={style.button}
                          onClick={() =>
                            navigate(
                              `/documentation/command/create?value=${item.id}&label=${item.label}`
                            )
                          }
                        >
                          <AddIcon />
                        </button>
                      ) : (
                        <></>
                      )}
                    </Tooltip>
                    {item.statistics.total ? (
                      <Tooltip title="Əmrlərə bax">
                        <button
                          className={style.button}
                          onClick={() =>
                            navigate(
                              `/documentation/contract/viewtypeofcontracts?contractID=${item.id}&contractType=${item.type}`
                            )
                          }
                        >
                          <RemoveRedEye />
                        </button>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
}
