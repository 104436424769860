import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

// Material UI
import {
  Button,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
} from "@mui/material";
import { Add as AddIcon, Visibility } from "@mui/icons-material";
//  Components
import Header from "../../../../components/layouts/header/Header";
// Services
import * as contractServices from "../../../../services/documentations/ContractServices";
import { getDescendantProp } from "../../../../utils/helperFunctions";
import Column from "../../../../models/table/Column";
import Table from "../../../../components/table/Table";
import { OPERATORS_CONTAINING, SUBJECT_TYPES } from "../../../../constants";
import { downloadFile } from "../../../../utils/downloadCSV";
import StatusGenerator from "../../../../components/table/StatusGenerator";
import Operator from "../../../../models/table/Operator";
import OperatorGenerator from "../../../../components/table/OperatorGenerator";
import DocumentDialog from "../../../../components/dialogs/documentDialog/DocumentDialog";
import { getDownloadCommands } from "../../../../services/structure/StructureRequestService";

const Divider = styled(MuiDivider)(spacing);

const OrdersRegister = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState();
  const [filters, setFilters] = useState([]);

  const [isShow, setIsShow] = useState(false);
  const [dataID, setDataID] = useState();

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  // const [data, setData] = useState([]);
  // const [dataDialog, setDataDialog] = useState(null);
  // const [isUpdated, setIsUpdated] = useState(false);

  const onCloseHandler = () => {
    setIsShow(false);
  };

  useEffect(() => {
    paginationFetchingHandler();
  }, [limit, offset, filters]);

  const paginationFetchingHandler = async () => {
    if (filters.length) {
      await getAllCommands([...filters]);
    } else {
      await getAllCommands();
    }
  };

  const getAllCommands = async (filters = undefined) => {
    try {
      setLoading(true);
      if (filters) {
        const { contracts, total } =
          await contractServices.getContractsWithFilters({
            limit: limit || 10,
            offset: offset || 0,
            filters: encodeURIComponent(
              JSON.stringify([
                ...filters,
                {
                  column: "contractType.type",
                  operator: "equalsTo",
                  value: "order",
                },
              ])
            ),
          });
        const _respDatas = contracts.map((type) => ({
          ...type,
          id: type._id,
        }));
        setDatas(_respDatas);
        setTotal(total);
      } else {
        const { contracts, total } = await contractServices.getContracts({
          limit: limit || 10,
          offset: offset || 0,
          filters,
          type: "order",
        });
        const _respDatas = contracts.map((type) => ({
          ...type,
          id: type._id,
        }));
        setDatas(_respDatas);
        setTotal(total);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };
  // const handleOpenDialog = (data) => {
  //   setDataDialog(data);
  //   setIsShow(true);
  // };

  // const handleCloseDialog = () => {
  //   setIsShow(false);
  //   setDataDialog(null);
  // };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  // downloadhandler

  const downloadHandler = async (columnsForExport) => {
    try {
      const colsExp = [];

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        colsExp.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const typeOfDoc = [
        {
          column: "contractType.type",
          operator: "equalsTo",
          value: "order",
        },
      ];

      const encodeColumns = encodeURIComponent(JSON.stringify(colsExp));
      const encodeFilters = encodeURIComponent(
        JSON.stringify([...filters, ...typeOfDoc])
      );
      const resp = await getDownloadCommands({
        columns: encodeColumns,
        filter: encodeFilters,
        isWithFilter: true,
      });
      if (resp) downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  const columns = [
    new Column({
      path: "contractType.label",
      headerName: "Ad",

      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "contractType.code",
      headerName: "Kod",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "contractType.templateName",
      headerName: "Faylın adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "docModel",
      headerName: "Document Model",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: SUBJECT_TYPES,
    }),
    new Column({
      path: "subjects.subject.firstname",
      headerName: "Əməkdaşın adı",
      filterColumnKey: "employeeFirstName",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      path: "subjects.subject.lastname",
      headerName: "Əməkdaşın soyadı",
      filterColumnKey: "employeeLastname",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.OPTION,
      isStaticColumn: true,

      renderCell: (row, column) => {
        const approvers = [];
        row.verifiers.map((item, index) => {
          approvers.push({
            index: index + 1,
            employee:
              item.employee?.lastname +
              " " +
              item.employee?.firstname +
              " " +
              item.employee?.fathername,
            status: item.status,
          });
        });
        return (
          <StatusGenerator
            approvement={approvers}
            value={getDescendantProp(row, column.path)}
            docType={"generalDocuments"}
          />
        );
      },
    }),

    new Column({
      path: "#",
      headerName: "Operatorlar",
      isStaticColumn: true,
      disableColSearch: true,
      renderCell: (row, column) => {
        const operators = [
          new Operator({
            icon: <Visibility />,
            onClick: () => {
              setDataID(row._id);

              setIsShow(true);
            },
            type: 0,
          }),
        ];

        return <OperatorGenerator operators={operators} />;
      },
    }),
  ];

  const headerData = {
    title: "Əmrlərin reyestri",
    variant: "h3",
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Header data={headerData} />
        </Grid>
        <Grid item>
          <Button
            sx={{ marginRight: "5px" }}
            variant="contained"
            color="primary"
            onClick={() => navigate("/documentation/orders")}
          >
            Əmrlər
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/documentation/orders/create")}
          >
            <AddIcon />
            Əmr Yarat
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} mb={6}>
          <Table
            columns={columns}
            data={datas}
            totalRows={total}
            offset={offset}
            setOffset={(value) => setOffset(value)}
            limit={limit}
            setLimit={(value) => setLimit(value)}
            isLoading={loading}
            applyFiltersHandler={applyFiltersHandler}
            cancelAppliedFilters={cancelAppliedFiltersHandler}
            downloadHandler={downloadHandler}
          />
        </Grid>
      </Grid>
      {isShow && (
        <DocumentDialog id={dataID} onClose={onCloseHandler} open={isShow} />
        // <SigningDialog
        //   open={isShow}
        //   onClose={handleCloseDialog}
        //   data={dataDialog}
        //   type={dataDialog.status}
        // />
      )}
    </>
  );
};

export default OrdersRegister;
