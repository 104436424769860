import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import React from "react";
import { useDispatch } from "react-redux";

// Material UI
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  CardContent,
  Card as MuiCard,
  Typography,
} from "@mui/material";

// Components
import ReactSelect from "../../layouts/select/ReactSelect";

// Services
import * as companyPartServices from "../../../services/companyPart/CompanyPartServices";
// Utils
import { BASE_URL } from "../../../constants";
import { setStructureId } from "../../../redux/features/slices/structureNaming.slice";
import style from "../style.module.css";

const Card = styled(MuiCard)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);

const CardItem = ({
  _id,
  organization,
  deletable,
  logo,
  onDelete,
  onView,
  structureId,
  onSaveCompanyPartsToStructure,
  onChange,
  onDeleteLogo,
}) => {
  const dispatch = useDispatch();
  // console.log(organization);
  const loadOptionsForCompanyParts = async (_search, loadedOptions) => {
    const _start = loadedOptions.length;
    const { parts, total } = await companyPartServices.getCompanyStructures({
      limit: 10,
      skip: _start,
      name: _search,
      type: structureId,
      notUsed: true,
    });

    return {
      options: parts.map((item) => ({
        value: item._id,
        label: item.name,
      })),
      hasMore: _start < total - 1,
    };
  };

  const classes = {
    flex: {
      display: "flex",
      alignItems: "center",
    },
    cardContent: {
      padding: "16px",
      justifyContent: "space-between",
    },
    mb_3: {
      marginBottom: "8px",
    },
    mr_4: {
      marginRight: "4px",
    },
    mr_6: {
      marginRight: "6px",
    },
    mr_15: {
      marginRight: "15px",
    },
    d_none: {
      display: "none",
    },
    border_radius_8: {
      borderRadius: "8px",
    },
    select_styles: {
      position: "absolute",
      maxWidth: "20%",
      color: "#333",
    },
  };

  return (
    <Card style={classes.mb_3} className={style?.card_item}>
      <CardContent style={{ ...classes.cardContent, ...classes.flex }}>
        <div style={classes.flex}>
          {logo ? (
            <span style={{ ...classes.flex, ...classes.mr_15 }}>
              <img
                src={`${BASE_URL}/employee/s/logo/${logo}`}
                alt={logo}
                width={30}
                height={30}
                style={{ ...classes.mr_6, ...classes.border_radius_8 }}
              />
            </span>
          ) : null}

          {organization && organization.name ? (
            <Typography variant="h6">{organization?.name}</Typography>
          ) : (
            <div
              style={
                logo
                  ? { ...classes.select_styles, left: "25%" }
                  : classes.select_styles
              }
            >
              <ReactSelect
                loadOptions={loadOptionsForCompanyParts}
                placehodler={"Struktur seçin"}
                isDisabled={organization?.name}
                _onChange={({ value }) =>
                  onSaveCompanyPartsToStructure(value, _id)
                }
              />
            </div>
          )}
        </div>
        <div
          className="icons"
          style={
            !(organization && organization.name)
              ? {
                  ...classes.flex,
                  width: "100%",
                  justifyContent: "end",
                }
              : null
          }
        >
          {logo ? (
            <span className="remove" style={classes.mr_6}>
              <Button
                variant="outlined"
                color={"error"}
                component="span"
                onClick={() => onDeleteLogo(_id)}
              >
                <DeleteIcon mr={2} color={"error"} /> Loqonu sil
              </Button>
            </span>
          ) : null}
          <span className="upload" style={classes.mr_6}>
            <input
              type="file"
              id="raised-button-file"
              name="templateFile"
              style={classes.d_none}
              accept="image/*"
              onChange={(e) => onChange(e)}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                onClick={() => dispatch(setStructureId(_id))}
              >
                <CloudUpload mr={2} /> Loqo yüklə
              </Button>
            </label>
          </span>
          <span className="view" style={classes.mr_6}>
            <Button
              variant="outlined"
              color="info"
              component="span"
              onClick={() => onView(_id)}
              disabled={!(organization && organization.name)}
            >
              <VisibilityIcon style={classes.mr_4} /> Daxil ol
            </Button>
          </span>

          <span className="remove">
            <Button
              variant="outlined"
              color={"error"}
              component="span"
              onClick={() => onDelete(_id)}
              disabled={!deletable}
            >
              <DeleteIcon mr={2} color={deletable ? "error" : "disabled"} /> Sil
            </Button>
          </span>
        </div>
      </CardContent>
    </Card>
  );
};

export default CardItem;
