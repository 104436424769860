import styled from "styled-components";

export const CUSTOM_BUTTON = styled.button`
  position: ${(props) => props?.poss};
  top: ${(props) => props?.topp};
  left: ${(props) => props?.leftt};
  bottom: ${(props) => props?.bottomm};
  right: ${(props) => props?.rightt};
  z-index: ${(props) => props?.zIndex};
  // border-bottom:  ${(props) =>
    props?.bord ? props?.bord : "2px solid #004EC2"};
  font-size: ${(props) => props?.f_size};
  text-transform: ${(props) => props?.textTrans};
  color: ${(props) => props?.clr};
  margin: ${(props) => props?.marg};
  font-size: ${(props) => props?.f_size};
  font-weight: ${(props) => props?.wei};
  font-family: ${(props) => props?.f_family};
  display: ${(props) => props?.d_play};
  padding: ${(props) => props?.pad};
  border-radius: ${(props) => props?.brad};
  background-color: ${(props) => (props?.dis ? props?.dis : props?.bg_color)};
  border: ${(props) => (props?.bord == "0" ? 0 : props?.bord)};
  border-bottom: ${(props) => props?.bordBot};
  border-top: ${(props) => props?.bordTop};
  border-left: ${(props) => props?.bordLeft};
  min-width: ${(props) => props?.m_wid};
  width: ${(props) => props?.wid};
  height: ${(props) => props?.$height};
  cursor: ${(props) => props?.dis && "not-allowed"};
  &:hover {
    background-color: ${(props) => props?.hover_bg};
    opacity: ${(props) => props?.hover_bg};
  }
`;
