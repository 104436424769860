// NOTE!!!
// PLEASE CONSIDER ALL COMMENTS BELOW
import React, { useEffect, useState } from "react";
import { getContract } from "../../../services/documentations/ContractServices";
import { toast } from "react-toastify";
import {
  exclusionOrderGenerator,
  structureOrderGenerator,
} from "./generatorFields/structureRequestOrder";
import { structureServiceGenerator } from "./generatorFields/structureServiceGenerator";
import { labourContractChangeGenerator } from "./generatorFields/labourContractChangeGenerator";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { approveLabourContract } from "../../../api/contractRequests/contractRequests";
import { COLORS_STATUS } from "../../../constants";
import { contractHandlers } from "./helpers";

const useDocumentDialogHook = (
  navList = [],
  id,
  loading,
  disableSigning,
  refreshPage,
  onClose,
  btnGroupLocationInApp
) => {
  const { currentEmployee } = useSelector((state) => state.commonThings);
  const [contractData, setContractData] = useState();
  const [fields, setFields] = useState([]);
  const [comments, setComments] = useState([]);
  const [status, setStatus] = useState(null);
  const [disableSign, setDisableSign] = useState(disableSigning);
  const [isGroupFields, setIsGroupFields] = useState(false);
  const [versionsList, setVersionsList] = useState([]);
  const [currentVersion, setCurrVersion] = useState("");
  const [relatedDocs, setRelatedDocs] = useState([]);
  const [currentNav, set_currentNav] = useState(0);
  const [sections, render_sections] = useState({
    info: true,
    doc: false,
    comment: false,
  });
  const [commmenDialog, renderCommmenDialog] = useState({
    type: null,
    isShow: false,
  });
  console.log(relatedDocs);
  useEffect(() => {
    if (navList && navList?.length > 0) {
      console.log(navList);
      setRelatedDocs(navList);
      const indexxx = navList?.findIndex(
        (item) => item?.value === contractData?._id
      );
      set_currentNav(indexxx);
    }
  }, [navList, contractData]);
  // console.log(navList, id, fetchHandler);

  const getData = async (_id, fetchHandler) => {
    console.log(_id);
    try {
      loading?.setIsLoading(true);
      const { data } = fetchHandler
        ? await fetchHandler(_id, true)
        : await getContract(_id, true);
      if (id === _id) {
        console.log("oh");
        generateNavList(data);
      }
      if (data) {
        setStatus(data?.status);
        setContractData(data);
        generateData(data);
      }
    } catch (error) {
      error.response.data.errors.map((item) => {
        let message = item.message;
        if (message === "You don't have enough privilege for view") {
          message = "Sizin bu hissəyə daxil olmaq üçün müvafiq icazəniz yoxdur";
        }
        return toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    } finally {
      loading?.setIsLoading(false);
    }
  };

  const generateData = (data) => {
    if (!data) return;
    // if (data.contractType.name === "structure-request-service") {
    //   setIsGroupFields(false);
    // } else {
    setIsGroupFields(true);
    // }
    generateFields(data);
    generateComments(data);
    generateStatus(data);
    generateVersions(data);
  };
  const generateNavList = (data) => {
    const list = [];

    if (navList && navList.length > 0) {
      setRelatedDocs(navList);
      return;
    }

    data?.relatedContracts.map((item, index) => {
      item.docId === id && set_currentNav(index);
      const obj = {
        label: normalizeDocName(item.contractType),
        value: item.docId,
      };
      // item.docId === data._id ? list.unshift(obj) :
      list.push(obj);
    });
    list.length > 1 && setRelatedDocs(list);
  };

  const generateVersions = (data) => {
    if (versionsList?.length > 0) return;
    const list = [];
    const versions = data.oldVersions;

    if (versions?.length === 0) {
      list.push({
        label: "1",
        value: id,
      });
    } else {
      versions?.map((item, index) => {
        list.push({
          label: `${index + 1}`,
          value: item.contract._id,
        });
      });

      list.push({
        label: `${versions?.length + 1}`,
        value: id,
      });
    }

    setCurrVersion(list[list.length - 1].value);
    setVersionsList(list);
  };
  const generateComments = (data) => {
    const verifiers = data?.verifiers;
    const _comments = [];
    const labelMsg = `Şərh: Mən `;

    const status = (status) => {
      switch (status) {
        case "approved":
          return "təsdiq edirəm";
        case "rejected":
          return "imtina edirəm";
        case ("pending", "draft"):
          return "qərar gözlənilir";
        default:
          return "...";
      }
    };

    if (verifiers?.length === 0) return;
    // eslint-disable-next-line array-callback-return
    verifiers?.map((item) => {
      // eslint-disable-next-line array-callback-return
      if (item.status === "pending") return;

      const employeeName = `${item.employee.firstname} ${item.employee.lastname}`;
      const positionName = item.employee?.position?.name
        ? ` (${item.employee.position.name})`
        : "";
      const label = `${labelMsg}${employeeName}${positionName} ${status(
        item.status
      )}`;

      _comments.push({
        label: label,
        value: item.description,
      });
    });
    // return _comments;
    setComments([..._comments]);
  };
  // console.log(generateComments(contractData), contractData);
  //   const generateHeader = (data) => {};
  const generateStatus = (data) => {
    // check verifier logged and status
    checkVerifierStatus(data);
    setStatus(data.status);
  };
  const navHandler = async (id) => {
    setFields([]);
    setComments();
    setStatus();
    setDisableSign(true);
    await getData(id, getContract);
  };
  const versionHandler = async (id) => {
    setFields([]);
    setComments();
    setStatus();
    setDisableSign(true);
    setCurrVersion(id);
    await getData(id, getContract);
  };
  const generateFields = (data) => {
    // if (data.contractType.name === "structure-request-service") {
    //   const arr = loopingFields(data.data);
    //   const subjectsField = findSubjectsFields(data.subjects);
    //   const generalDetails = findGeneralDetails(data);
    //   arr.unshift(subjectsField, ...generalDetails);
    //   setFields([...arr]);
    //   return;
    // }
    if (data.contractType.name === "structure-request-order") {
      const fields = structureOrderGenerator(data);
      setFields(fields);
      return;
    }
    if (data.contractType.name === "structure-request-service") {
      const fields = structureServiceGenerator(data);
      setFields(fields);
      return;
    }
    if (data.contractType.name === "labourContractUpdate") {
      const fields = labourContractChangeGenerator(data);
      setFields(fields);
      return;
    }
    if (data.contractType.name === "exclusion-order") {
      const fields = exclusionOrderGenerator(data);
      setFields(fields);
      return;
    }
    setFields(data.data);
  };

  const checkVerifierStatus = (data) => {
    // signing buttons show only pending contract
    if (data.status !== "pending") {
      setDisableSign(true);
      return;
    }

    // logged user has on signers
    const founded = data.verifiers?.find(
      (item) => item.employee.id === currentEmployee._id
    );

    // if will not
    if (!founded || founded.status !== "pending") {
      setDisableSign(true);
      return;
    }

    // if (data.selfSign) {
    //   setDisableSign(true);
    //   return;
    // }

    setDisableSign(false);
  };
  const generateBgColor = () => {
    if (status) {
      switch (status) {
        case "draft":
        case "pending":
          return COLORS_STATUS.BLUE;
        case "approved":
          return COLORS_STATUS.GREEN;
        case "rejected":
          return COLORS_STATUS.RED;
        case "pendingUpdate":
          return COLORS_STATUS.BLUE;
        case "updated":
          return COLORS_STATUS.GREEN;
        default:
          return COLORS_STATUS.GREY;
      }
    }
  };
  const generatedHeader = React.useMemo(() => {
    const docType = contractHandlers?.findDocType(
      contractData?.contractType?.type
    );
    switch (status) {
      case "updated":
        return `Yenilənmiş ${docType}`;
      case "draft":
        return `Təsdiq gözləyən ${docType}`;
      case "pending":
        return `İmza gözləyən ${docType}`;
      case "approved":
        return `Qüvvədə olan ${docType}`;
      case "pendingUpdate":
        return `İmza gözləyən dəyişiklik edilmiş ${docType}`;
      case "rejected":
        return `İmtina edilmiş ${docType}`;
      default:
        return "Sorğu";
    }
  }, [contractData?.contractType?.type, status]);

  const docNumberHandler = () => {
    const docNo = contractData
      ? validateValue(
          contractData?.data?.contract_general_info?.map((item) => {
            if (item.key === "docNumber") return item.value;
          })
        )
      : validateValue(
          contractData?.data?.document_general_info?.map((item) => {
            if (item.key === "docNumber") {
              return item.value;
            }
          })
        );
    return docNo;
  };

  return {
    contractData,
    setContractData,
    fields,
    status,
    relatedDocs,
    setRelatedDocs,
    versionHandler,
    navHandler,
    render_sections,
    sections,
    docNumberHandler,
    generateBgColor,
    generatedHeader,
    renderCommmenDialog,
    // appLabourContract,
    comments,
    getData,
  };
};

export default useDocumentDialogHook;

const normalizeDocName = (key) => {
  switch (key) {
    case "employment-application":
      return "Ərizə";
    case "labour-contract":
      return "Müqavilə";
    case "labour-order":
      return "Əmr";
    case "vacation-order":
      return "Məzuniyyət əmri";
    case "vacation-application":
      return "Məzuniyyət ərizəsi";
    case "labour-termination-order":
      return "Xitam əmri";
    default:
      return key;
  }
};

const validateValue = (value) => {
  return value && value.length > 0 ? value : "";
};
