import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import swal from "sweetalert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  empConstInitialValues,
  empConstModifeValue,
  empGeneralConstInitialValues,
  empGeneralConstModifeValue,
  weekDays,
} from "./empConstMock/empConstMock";
import LabourPeriod from "./component/LabourPeriod";
import Wage from "./component/Wage";
import Protection from "./component/Protection";
import WorkVacationTime from "./component/WorkVacationTime";
import VacationForDialog from "./component/VacationForDialog";
import PhysicalEducationSport from "./component/PhysicalEducationSport";
import SocialProtection from "./component/SocialProtection";
import RegulationPropertyRelation from "./component/RegulationPropertyRelation";
import { getPagesData } from "../../../services/documentations/ContractServices";
import StructureSelecting from "../parts/modal/parts/StructureSelecting";
import { removeEmptyStrings } from "../../../utils/removeEmptyString";
import { updateContract } from "../../../services/labourContractUpdate/labourContractUpdateService";
import InformationAboutParties from "./component/InformationAboutParties";

const EmpConstsGeneralDialog = ({ fetchData, open, setOpen }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedValue, setSelectedValue] = useState([]);
  const [contId, setContId] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [employees, setEmployee] = useState([]);
  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [selectedEmpData, setSelectedEmpData] = useState(null);
  const handleFetchEmps = async () => {
    try {
      //   const response = await getEmployees();
      const res = await getPagesData("reasonOfTemporaryContract");

      setSelectedValue(res?.contracts?.static?.labour);
      //   setEmps(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleFetchEmps();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleStaffsId = (arr) => {
    setStaffs(arr);
  };
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const modifeValue = empGeneralConstModifeValue(values, employees);
    const result = removeEmptyStrings(modifeValue);

    try {
      const willSubmit = await swal({
        title: "Əminsiniz mi?",
        text: "Mövcud müqaviləyə dəyişiklik etməyə əminsiniz?",
        icon: "warning",
        dangerMode: true,
        buttons: ["Ləğv et", "Tətbiq et"],
      });
      if (willSubmit) {
        const resp = await updateContract(result);
        if (resp) {
          handleClose();
          swal(
            "Tamamlandı!",
            "Əmək müqavilələrinə dəyişiklik sorğusu göndərildi!",
            "success"
          );
          resetForm();
          await fetchData();
        }
      }
    } catch (error) {
      swal("Xəta", "Xəta baş verdi", "error");
      console.log("err", error);
    }

    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            width: "70vw",
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }} id="responsive-dialog-title">
          {"Ə.M. kütləvi dəyişiklik"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={empGeneralConstInitialValues}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              isValid,
              dirty,
            }) => (
              <Form>
                <div style={{ width: "100%", display: "flex" }}>
                  <div
                    style={{
                      width: "50%",
                      height: "400px",
                      overflow: "scroll",
                    }}
                  >
                    {staffs.length > 0 && (
                      <>
                        <InformationAboutParties
                          errors={errors}
                          values={values}
                          setFieldValue={setFieldValue}
                          selectedValue={selectedValue}
                        />
                        <LabourPeriod
                          values={values}
                          setFieldValue={setFieldValue}
                          selectedValue={selectedValue}
                        />
                        <Wage
                          values={values}
                          weekDays={weekDays}
                          setFieldValue={setFieldValue}
                          selectedValue={selectedValue}
                        />
                        <Protection
                          values={values}
                          setFieldValue={setFieldValue}
                          selectedValue={selectedValue}
                          inventoryTypes={inventoryTypes}
                        />
                        <WorkVacationTime
                          values={values}
                          weekDays={weekDays}
                          selectedValue={selectedValue}
                          setFieldValue={setFieldValue}
                        />
                        <VacationForDialog
                          selectedValue={selectedValue}
                          setFieldValue={setFieldValue}
                          selectedEmpData={selectedEmpData}
                        />
                        <PhysicalEducationSport setFieldValue={setFieldValue} />
                        <SocialProtection setFieldValue={setFieldValue} />
                        <RegulationPropertyRelation
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </>
                    )}
                  </div>
                  <div style={{ width: "50%" }}>
                    <StructureSelecting
                      onSelect={handleStaffsId}
                      contIdHandler={(id) => setContId(id)}
                      setInventoryTypes={setInventoryTypes}
                      setSelectedEmpData={setSelectedEmpData}
                      setEmployee={setEmployee}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    paddingBottom: "15px",
                  }}
                >
                  <Button
                    color="error"
                    variant="contained"
                    title="Ləğv et"
                    sx={{ width: "50%" }}
                    autoFocus
                    onClick={handleClose}
                  >
                    <Typography>Ləğv et</Typography>
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    autoFocus
                    sx={{ width: "50%" }}
                    disabled={isSubmitting}
                  >
                    Tətbiq et
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default EmpConstsGeneralDialog;
