import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useReducer } from "react";
import { useState } from "react";
import {
  createDecisionChain,
  getAllStructures,
  getNestedChildren,
  updateDecisionChain,
} from "../../api/api";
import NestedSelect from "./NestedSelect";
import EditDecisionForm from "./EditDecisionForm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditDecisionChain = ({ state, dispatch, isLoading }) => {
  const {
    addDecisionChainDialog,
    allStructures,
    structureId,
    organizationId,
    nestedChildren,
    editDecisionDialog,
    positionId,
    approvementChain,
    submitted,
    selectedDecisionChain,
  } = state;

  const [editStructurePart, setEditStructurePart] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [fullSelectName, setFullSelectName] = useState("");
  // Returning id
  function extractIdsFromObject(obj) {
    if (Array.isArray(obj)) {
      return obj.map(extractIdsFromObject);
    } else if (typeof obj === "object" && obj !== null) {
      if (obj.hasOwnProperty("_id")) {
        return obj._id;
      } else if (obj.hasOwnProperty("id")) {
        return obj.id;
      } else {
        const newObj = {};
        for (const key in obj) {
          newObj[key] = extractIdsFromObject(obj[key]);
        }
        return newObj;
      }
    } else {
      return obj;
    }
  }

  // Get all structures
  useEffect(() => {
    const handleAllStructures = async () => {
      try {
        const response = await getAllStructures();
        dispatch({
          type: "GET_ALL_STRUCTURES",
          payload: response.data.structures,
        });
      } catch (error) {
        console.log("err", error);
      }
    };
    handleAllStructures();
  }, []);

  // Handle organization name
  const handleOrganizationName = async (e) => {
    dispatch({
      type: "SET_LOADING_DIALOG",
      payload: true,
    });
    dispatch({
      type: "SET_STRUCTURE_ID",
      payload: e.target.value._id,
    });
    dispatch({
      type: "SET_ORGANIZATION_ID",
      payload: e.target.value.organization._id,
    });

    const response = await getNestedChildren(
      e.target.value._id,
      e.target.value.organization._id
    );
    dispatch({
      type: "GET_NESTED_CHILDREN",
      payload: response.data,
    });
    dispatch({
      type: "SET_LOADING_DIALOG",
      payload: false,
    });
  };

  const handleDialogClose = () => {
    dispatch({
      type: "SET_EDIT_DECISION_DIALOG",
      payload: false,
    });
    dispatch({
      type: "SET_STRUCTURE_ID",
      payload: null,
    });
    dispatch({
      type: "SET_ORGANIZATION_ID",
      payload: null,
    });
    dispatch({
      type: "SET_POSITION_ID",
      payload: null,
    });
    dispatch({
      type: "SET_SELECTED_DECISION_CHAIN",
      payload: null,
    });
    dispatch({
      type: "SET_ROW_ID",
      payload: "",
    });
    setEditStructurePart(false);
  };

  // Handle Submit
  const handleSubmit = async () => {
    dispatch({
      type: "SET_LOADING_DIALOG",
      payload: true,
    });
    const lastApprovementChain = extractIdsFromObject(approvementChain);
    await updateDecisionChain(
      selectedDecisionChain._id,
      structureId,
      selectedDecisionChain.positionPartRel._id,
      lastApprovementChain
    );
    dispatch({
      type: "SET_SUBMITTED",
      payload: !submitted,
    });
    dispatch({
      type: "SET_EDIT_DECISION_DIALOG",
      payload: false,
    });
    dispatch({
      type: "SET_LOADING_DIALOG",
      payload: false,
    });
    setFullSelectName("");
    setIsValid(true);
  };

  // Put structures changes
  const handleStructureChanges = async () => {
    dispatch({
      type: "SET_LOADING_DIALOG",
      payload: true,
    });
    const updatedApproveChain = approvementChain.map((item) => item._id);

    await updateDecisionChain(
      selectedDecisionChain._id,
      structureId,
      positionId,
      updatedApproveChain
    );

    dispatch({
      type: "SET_SUBMITTED",
      payload: !submitted,
    });
    setEditStructurePart(false);
    dispatch({
      type: "SET_LOADING_DIALOG",
      payload: false,
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={editDecisionDialog}
        onClose={() => {
          handleDialogClose();
          setIsValid(true);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography p={5} textAlign="center" variant="h3">
            Matrisin Dəyişdirilməsi
          </Typography>
        </DialogTitle>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <DialogContent sx={{ px: "50px" }}>
            <Grid sx={{ display: "flex", width: "100%", gap: 15 }}>
              <Grid
                sx={{
                  width: 1 / 3,
                  py: 2,
                  maxHeight: "55vh",
                  overflowY: "auto",
                }}
              >
                {editStructurePart ? (
                  <>
                    <FormControl sx={{ mb: 6 }} fullWidth>
                      <InputLabel id="organizationName">
                        Təşkilatı seçin
                      </InputLabel>
                      <Select
                        labelId="organizationName"
                        id="organizationName"
                        input={<Input />}
                        fullWidth
                        MenuProps={MenuProps}
                        onChange={(e) => {
                          handleOrganizationName(e);
                        }}
                      >
                        {allStructures.map((structure, index) => {
                          if (structure.organization.name) {
                            return (
                              <MenuItem key={index} value={structure}>
                                {structure.organization.name}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                    {structureId && organizationId && nestedChildren !== "" ? (
                      <NestedSelect state={state} dispatch={dispatch} />
                    ) : null}
                  </>
                ) : (
                  <>
                    {" "}
                    <FormControl sx={{ mb: 4 }} fullWidth>
                      <Typography>Təşkilat</Typography>
                      <TextField
                        m={2}
                        disabled
                        id="standard-disabled"
                        label={
                          selectedDecisionChain?.structure?.organization?.name
                        }
                      />
                    </FormControl>
                    <FormControl sx={{ mb: 4 }} fullWidth>
                      <Typography>Struktur</Typography>
                      <TextField
                        m={2}
                        disabled
                        id="standard-disabled"
                        label={
                          selectedDecisionChain?.topCompanyPartRel?.source?.name
                        }
                      />
                    </FormControl>
                    <FormControl sx={{ mb: 4 }} fullWidth>
                      <Typography>Substruktur</Typography>
                      <TextField
                        m={2}
                        disabled
                        id="standard-disabled"
                        label={
                          selectedDecisionChain?.companyPartRel?.source?.name
                        }
                      />
                    </FormControl>
                    <FormControl sx={{ mb: 4 }} fullWidth>
                      <Typography>Vəzifə</Typography>
                      <TextField
                        m={2}
                        disabled
                        id="standard-disabled"
                        label={
                          selectedDecisionChain?.positionPartRel?.source?.name
                        }
                      />
                    </FormControl>
                  </>
                )}
                <Grid>
                  {editStructurePart ? (
                    <Grid
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Button
                        onClick={() => {
                          setEditStructurePart(false);
                          dispatch({
                            type: "SET_POSITION_ID",
                            payload: null,
                          });
                        }}
                        color="primary"
                      >
                        Ləğv et
                      </Button>
                      <Button
                        disabled={isValid}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          handleStructureChanges();
                        }}
                      >
                        Yadda Saxla
                      </Button>
                    </Grid>
                  ) : (
                    <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        onClick={() => {
                          setEditStructurePart(true);
                        }}
                        color="primary"
                      >
                        Dəyiş
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                sx={{
                  width: 2 / 3,
                  py: 2,
                  maxHeight: "55vh",
                  overflowY: "auto",
                }}
              >
                {structureId ? (
                  <EditDecisionForm
                    setIsValid={setIsValid}
                    fullSelectName={fullSelectName}
                    setFullSelectName={setFullSelectName}
                    state={state}
                    dispatch={dispatch}
                  />
                ) : null}
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              dispatch({
                type: "SET_EDIT_DECISION_DIALOG",
                payload: false,
              });
            }}
          >
            Ləğv et
          </Button>
          <Button
            disabled={isValid}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Yadda saxla
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDecisionChain;
