import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FieldArray, useFormikContext } from "formik";
import React from "react";
import { banks } from "../../constants/constants";

const BankInfo = ({ values }) => {
  const { validateForm, errors, setErrors, setFieldValue } = useFormikContext();

  const handleBankSelect = (item) => {
    const selectBank = banks.find((bank) => {
      return bank.name === item;
    });
    return selectBank;
  };
  const arrayMonth = Array.from({ length: 12 }, (_, index) => {
    const month = index + 1;
    return month < 10 ? `0${month}` : `${month}`;
  });
  const years = Array.from({ length: 2099 - 2024 + 1 }, (_, index) => {
    const year = 2024 + index;
    return `${year}`;
  });

  return (
    <>
      <FieldArray name="bankInfos">
        {({ push, remove }) => (
          <div>
            {values?.bankInfos &&
              values?.bankInfos.map((form, index) => (
                <div key={index}>
                  <>
                    <Typography mt={3} mb={3} variant="h6">
                      {index + 1})Bank məlumatları
                    </Typography>

                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.bankInfos?.[index]?.bankName &&
                            Boolean(errors?.bankInfos?.[index]?.bankName)
                          }
                          fullWidth
                        >
                          <InputLabel id={`bankInfos[${index}].bankName`}>
                            Banklar *
                          </InputLabel>
                          <Field
                            as={Select}
                            name={`bankInfos[${index}].bankName`}
                            labelId={`bankInfos[${index}].bankName`}
                            label="Banklar *"
                            id="bankName-select"
                            onChange={(e) => {
                              setFieldValue(
                                `bankInfos[${index}].bankName`,
                                e.target.value
                              );
                              setFieldValue(
                                `bankInfos[${index}].bankCode`,
                                handleBankSelect(e.target.value).code
                              );
                              setFieldValue(
                                `bankInfos[${index}].bankVoen`,
                                handleBankSelect(e.target.value).voen
                              );
                              if (errors?.bankInfos?.[index]?.bankName) {
                                setErrors({
                                  ...errors,
                                  bankInfos: [
                                    ...errors.bankInfos.slice(0, index),
                                    {
                                      ...errors.bankInfos[index],
                                      bankName: "",
                                    },
                                    ...errors.bankInfos.slice(index + 1),
                                  ],
                                });
                              }
                            }}
                            error={
                              errors?.bankInfos?.[index]?.bankName &&
                              Boolean(errors?.bankInfos?.[index]?.bankName)
                            }
                            fullWidth
                          >
                            {banks &&
                              banks
                                .sort((a, b) =>
                                  a.name.localeCompare(b.name, "az")
                                )
                                .map((item, index) => (
                                  <MenuItem key={index} value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                          </Field>
                          {errors?.bankInfos?.[index]?.bankName &&
                            errors?.bankInfos?.[index]?.bankName && (
                              <FormHelperText error>
                                {errors?.bankInfos?.[index]?.bankName}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="number"
                          name={`bankInfos[${index}].bankCode`}
                          as={TextField}
                          label="Bank kod"
                          value={values.bankInfos[index].bankCode}
                          defaultValue={values.bankInfos[index].bankCode}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name={`bankInfos[${index}].bankVoen`}
                          as={TextField}
                          label="Bank VÖEN"
                          value={values.bankInfos[index].bankVoen}
                          defaultValue={values.bankInfos[index].bankVoen}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name={`bankInfos[${index}].accountNumber`}
                          as={TextField}
                          error={
                            errors?.bankInfos?.[index]?.accountNumber &&
                            Boolean(errors?.bankInfos?.[index]?.accountNumber)
                          }
                          helperText={
                            errors?.bankInfos?.[index]?.accountNumber &&
                            errors?.bankInfos?.[index]?.accountNumber
                          }
                          label="Müxbir Hesab nömrəsi *"
                          fullWidth
                          onChange={async (e) => {
                            setFieldValue(
                              `bankInfos[${index}].accountNumber`,
                              e.target.value
                            );
                            if (errors?.bankInfos?.[index]?.accountNumber) {
                              setErrors({
                                ...errors,
                                bankInfos: [
                                  ...errors.bankInfos.slice(0, index),
                                  {
                                    ...errors.bankInfos[index],
                                    accountNumber: "",
                                  },
                                  ...errors.bankInfos.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name={`bankInfos[${index}].iban`}
                          as={TextField}
                          label="IBAN *"
                          error={
                            errors?.bankInfos?.[index]?.iban &&
                            Boolean(errors?.bankInfos?.[index]?.iban)
                          }
                          helperText={
                            errors?.bankInfos?.[index]?.iban &&
                            errors?.bankInfos?.[index]?.iban
                          }
                          fullWidth
                          onChange={async (e) => {
                            setFieldValue(
                              `bankInfos[${index}].iban`,
                              e.target.value
                            );
                            if (errors?.bankInfos?.[index]?.iban) {
                              setErrors({
                                ...errors,
                                bankInfos: [
                                  ...errors.bankInfos.slice(0, index),
                                  {
                                    ...errors.bankInfos[index],
                                    iban: "",
                                  },
                                  ...errors.bankInfos.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid
                        sx={{
                          width: "100%",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <FormControl
                          sx={{
                            width: "20%",
                          }}
                        >
                          <Field
                            as={Select}
                            name={`bankInfos[${index}].month`}
                            // defaultValue={
                            //   values?.bankInfos?.[index]?.cardExpirationDate
                            //     ? values.bankInfos[
                            //         index
                            //       ].cardExpirationDate.split("/")[0]
                            //     : ""
                            // }
                            fullWidth
                            onChange={async (e) => {
                              setFieldValue(
                                `bankInfos[${index}].month`,
                                e.target.value
                              );
                            }}
                          >
                            {arrayMonth &&
                              arrayMonth.map((item, index) => (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              ))}
                          </Field>
                        </FormControl>
                        <FormControl
                          sx={{
                            width: "80%",
                          }}
                        >
                          <InputLabel>Kartın etibarlıq müddəti</InputLabel>
                          <Field
                            as={Select}
                            label="Kartın etibarlıq müddəti"
                            name={`bankInfos[${index}].year`}
                            fullWidth
                            onChange={async (e) => {
                              setFieldValue(
                                `bankInfos[${index}].year`,
                                e.target.value
                              );
                            }}
                          >
                            {years.map((item, index) => (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>

                  <Button
                    sx={{ mt: 3 }}
                    variant="contained"
                    color="error"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    Bank məlumatını sil
                  </Button>
                </div>
              ))}
            <Button
              sx={{ mt: 3 }}
              variant="contained"
              type="button"
              onClick={() => push({ bankCode: "", bankVoen: "" })}
            >
              Bank məlumatları əlavə et
            </Button>
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default BankInfo;
