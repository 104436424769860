import { CloudDownload, ExpandMore, CompareArrows } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DocSVG from "../../../layouts/signing/dialog/docSVG/DocSVG";
import { GROUP_NAME } from "./dialogConstants";
import { GENERAL_BLOCK } from "./styled";
import moment from "moment";
import { translationMap } from "./helpers";
import CommentInput from "./dialogParts/parts/CommentInput";

const DialogContent = ({
  fields,
  href,
  buttonGroup,
  render_sections,
  comments,
  contractNo,
  version,
  navigation,
  generateHeader,
  generateBgColor,
}) => {
  function renderValue(item, dataVersionIdentifier) {
    let value;
    // console.log(item?.key === "verifiers" && item?.value, item?.key);
    // !item?.value ||
    if (item?.value === "") return "---";
    if (item?.key === "verifiers") {
      if (item?.value?.some((item) => item?.date)) {
        value = moment(item?.date)?.format("DD/mm/yyyy");
      } else {
        value = "---";
      }
      // console.log(value, "verifiers");
    }

    if (Array.isArray(item?.value)) {
      if (item?.key !== "verifiers") {
        value = item?.value?.map((item) => {
          if (typeof item === "object") {
            return item?.value;
          } else {
            return item;
          }
        });
      }
    } else {
      switch (item?.type) {
        case "date":
          value = moment(item?.value)?.format("DD/mm/yyyy");
          break;
        case "object":
          value =
            dataVersionIdentifier === "old"
              ? item?.value?.old
              : dataVersionIdentifier === "new"
              ? item?.value?.new
              : item?.value;
          if (item?.key === "wageBonus") {
            if (dataVersionIdentifier === "old") {
              value = `${item?.value?.old?.value} ${
                wageBonus[item?.value?.old?.type] // backend_instance = item: {value: {old: {type: "manat": value: "..."}}, new: {type: "manat": value: "..."}}}
              }`;
            } else if (dataVersionIdentifier === "new") {
              value = `${item?.value?.new?.value} ${
                wageBonus[item?.value?.new?.type] // backend_instance = item: {value: {old: {type: "manat": value: "..."}}, new: {type: "manat": value: "..."}}}
              }`;
            } else {
              value = `${item?.value?.value} ${
                wageBonus[item?.value.type] // backend_instance = item: {value: {type: "manat": value: "..."}}
              }`;
            }
          }
          if (item?.key === "probationPeriod") {
            if (dataVersionIdentifier === "old") {
              value = `${item?.value?.old?.value} ${
                probationPeriod[item?.value?.old?.type] // backend_instance = item: {value: {old: {type: "day": value: "..."}}, new: {type: "day": value: "..."}}}
              }`;
            } else if (dataVersionIdentifier === "new") {
              value = `${item?.value?.new?.value} ${
                probationPeriod[item?.value?.new?.type] // backend_instance = item: {value: {old: {type: "day": value: "..."}}, new: {type: "day": value: "..."}}}
              }`;
            } else {
              value = `${item?.value?.value} ${
                probationPeriod[item?.value.type] // backend_instance = item: {value: {type: "day": value: "..."}}
              }`;
            }
          }
          if (item?.key === "workingCondition") {
            value =
              dataVersionIdentifier === "old"
                ? item?.value?.old?.name
                : dataVersionIdentifier === "new"
                ? item?.value?.new?.name
                : item?.value?.name;
          }
          break;
        case "number":
          if (item?.key === "probationRefusalTime") {
            value = `${item?.value?.value} ${
              item?.value.type === "day"
                ? "gün"
                : item?.value.type === "month"
                ? "ay"
                : "il"
            }`;
            console.log(value);
          } else {
            value =
              dataVersionIdentifier === "old"
                ? item?.value?.old
                : dataVersionIdentifier === "new"
                ? item?.value?.new
                : item?.value;
            if (item?.key === "wage") {
              value = `${value} azn`;
            }
          }
          break;
        default:
          value =
            dataVersionIdentifier === "old"
              ? item?.value?.old
              : dataVersionIdentifier === "new"
              ? item?.value?.new
              : item?.value;
          break;
      }
    }
    let v = translationMap[value] ? translationMap[value] : value;
    return !value || value?.length === 0
      ? "---"
      : Array.isArray(value)
      ? value.join(",")
      : v;
  }

  const generateVersion = () => {
    if (version?.versionsList?.length <= 1) {
      return <MenuItem value={0}>1</MenuItem>;
    }

    return version?.versionsList?.map((item, index) => {
      return (
        <MenuItem
          key={index}
          value={item.value}
          sx={{ backgroundColor: "#0f0f0f" }}
        >
          {item.label}
        </MenuItem>
      );
    });
  };

  const [tab_value, set_tab_value] = useState(
    navigation.currentNav !== undefined ? navigation.currentNav : 0
  );

  const handleNav = (i, id) => {
    set_tab_value(i);
    navigation?.navHandler(id);
  };

  return (
    <>
      <div
        style={{ backgroundColor: generateBgColor() }}
        className="position-relative"
      >
        {navigation?.navList?.length > 0 && (
          <div className="w-100 justify-content-end d-flex position-absolute">
            {navigation?.navList?.map((item, i) => (
              <>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: tab_value === i && generateBgColor(),
                  }}
                  onClick={() => tab_value !== i && handleNav(i, item?.value)}
                >
                  {item.label}
                </Button>
              </>
            ))}
          </div>
        )}
        <div className="text-center w-100 mt-3" style={{ fontSize: "20px" }}>
          {generateHeader}
          {contractNo && (
            <div
              className="p-3 d-flex aic"
              // marginTop={"5px"}
              // marginLeft={"10px"}
              // sx={{
              //   display: "flex",
              //   height: "20px",
              // }}
            >
              {version?.versionsList && (
                <FormControl>
                  <div style={{ height: "100%", marginRight: "4px" }}>
                    V.{" "}
                    <Select
                      size="small"
                      disabled={version?.versionsList.length <= 1}
                      value={version?.currentVersion}
                      // defaultValue={versionData[versionData.length - 1].value}
                      sx={{
                        height: "20px",
                      }}
                      onChange={(e) => version?.versionHandler(e.target.value)}
                    >
                      {generateVersion()}
                    </Select>
                  </div>
                </FormControl>
              )}

              <Typography fontWeight={"bold"}>{contractNo}</Typography>
            </div>
          )}
        </div>
        <div className="p-3">
          {render_sections?.state?.info && (
            <GENERAL_BLOCK style={{ overflow: "scroll", height: "70vh" }}>
              {Object.keys(fields)?.map((key) => {
                if (key === "updates") {
                  return (
                    <>
                      <div
                        className="text-center mt-4 mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Dəyişikliklər
                      </div>
                      {Object.keys(fields[key])?.map((k) => (
                        <Accordion
                          sx={{ backgroundColor: "rgba(0,0,0,0.1)" }}
                          // key={key}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                color: "rgba(200,200,200,1)",
                              }}
                            >
                              {GROUP_NAME[k]}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails sx={{ marginX: "10px" }}>
                            {fields[key][k]?.map((item) => (
                              <div>
                                <Typography
                                  gutterBottom
                                  sx={{ color: "text.secondary", fontSize: 14 }}
                                >
                                  {item?.label}
                                </Typography>
                                <Typography variant="h6" component="div">
                                  {renderValue(item, "old")} <CompareArrows />{" "}
                                  {renderValue(item, "new")}
                                </Typography>
                                <hr />
                              </div>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>
                  );
                } else {
                  return (
                    <Accordion
                      sx={{ backgroundColor: "rgba(0,0,0,0.1)" }}
                      // key={key}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "rgba(200,200,200,1)",
                          }}
                        >
                          {GROUP_NAME[key]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ marginX: "10px" }}>
                        {key !== "updates" &&
                          fields[key]?.map((item) => (
                            <div>
                              <Typography
                                gutterBottom
                                sx={{ color: "text.secondary", fontSize: 14 }}
                              >
                                {item?.label}
                              </Typography>
                              <Typography variant="h6" component="div">
                                {renderValue(item)}
                              </Typography>
                              <hr />
                            </div>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}
            </GENERAL_BLOCK>
          )}
          <GENERAL_BLOCK>
            {render_sections?.state?.doc && (
              <>
                <div style={styles.container}>
                  <DocSVG style={styles.doc} />
                  <Grid sx={styles.downBtnCont}>
                    <IconButton
                      sx={styles.downloadBtn}
                      href={href}
                      target="_blank"
                      // disabled={downloadBtnProps.disable}
                    >
                      <CloudDownload sx={{ fontSize: 32, marginRight: 2 }} />
                      <Typography>Sənədi yüklə</Typography>
                    </IconButton>
                  </Grid>
                </div>
              </>
            )}
            {render_sections?.state?.comment && (
              <>
                {comments?.length > 0 &&
                  comments?.map((item, index) => {
                    if (!item.label)
                      return <p key={item}>Melumat duzgun deyil</p>;
                    return (
                      <CommentInput
                        key={index}
                        label={item.label}
                        value={item.value}
                      />
                    );
                  })}
              </>
            )}
            <div className="d-flex">
              {buttonGroup?.map((btn) => (
                <Button
                  onClick={btn?.onClick}
                  className={`mt-2 ${btn?.gap}`}
                  size="small"
                  fullWidth
                  variant="outlined"
                  color={btn?.color}
                >
                  {btn?.label}
                </Button>
              ))}
              {comments?.length && (
                <Button
                  onClick={() =>
                    render_sections?.set((prev) => ({
                      ...prev,
                      doc: false,
                      info: prev?.comment ? true : false,
                      comment: !prev?.comment,
                    }))
                  }
                  className={`mt-2 ms-1`}
                  size="small"
                  fullWidth
                  variant="outlined"
                  color="warning"
                >
                  Rəylər
                </Button>
              )}
              {render_sections?.state?.doc ? (
                <Button
                  onClick={() =>
                    render_sections.set((prev) => ({
                      ...prev,
                      doc: false,
                      info: !prev?.info,
                      // comment: false,
                    }))
                  }
                  fullWidth
                  className="mt-2 ms-1"
                  variant="outlined"
                >
                  Məlumatlara bax
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    render_sections.set((prev) => ({
                      ...prev,
                      doc: !prev?.doc,
                      info: false,
                      comment: false,
                    }))
                  }
                  fullWidth
                  className="mt-2 ms-1"
                  variant="outlined"
                >
                  Sənədə bax
                </Button>
              )}
            </div>
          </GENERAL_BLOCK>
        </div>
      </div>
    </>
  );
};

export default DialogContent;

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  docCont: {
    height: "350px",
    position: "relative",
  },
  doc: {
    width: "260px",
    height: "350px",
    backgroundColor: "#fff",
  },
  downBtnCont: {
    width: "100%",
    marginTop: "10px",
    // position: "absolute",
    bottom: "25px",
    display: "flex",
    justifyContent: "center",
  },
  downloadBtn: {
    margin: "auto",
    width: "100%",
    backgroundColor: "#d9d9d9",
    borderRadius: 1,
    color: "rgba(0,0,0,0.7)",
    "&:hover": {
      backgroundColor: "grey",
      color: "#fff",
    },
  },
  commentCont: {
    width: "100%",
    paddingX: "20px",
    overflow: "hidden",
    height: "100%",
    overflow: "auto",
    marginTop: "5px",
  },
  commentContainer: { width: "100%", marginTop: 5 },
  input: {
    backgroundColor: "#FFF",
    color: "#000",
    paddingY: 5,
    paddingX: "40px",
    width: "100%",
    borderRadius: 3,
    lineHeight: "24px",
  },
};

const probationPeriod = {
  month: "ay",
  day: "gün",
  year: "il",
  none: "yoxdur",
};

const wageBonus = {
  manat: "azn",
  perctentage: "faiz",
};
