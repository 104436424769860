import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { getCommonData, updateEmployee } from "../../api/api";
import moment from "moment";
import { certificationsValidation } from "../employees/Validations";

const ViewCertification = ({ employeeById }) => {
  const [certification, setCertification] = useState([]);

  useEffect(() => {
    const handleCertification = async () => {
      const response = await getCommonData("certificates");
      setCertification(response.data);
    };
    handleCertification();
  }, []);

  const initialValues = {
    certifications: employeeById.certifications || [],
  };

  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={initialValues}
          validationSchema={certificationsValidation}
          onSubmit={(values, { setSubmitting }) => {
            updateEmployee(employeeById.id, values).finally(() =>
              setSubmitting(false)
            );
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            isSubmitting,
            handleChange,
            setFieldValue,
            setErrors,
          }) => (
            <Form style={{ pointerEvents: "none" }}>
              <FieldArray name="certifications">
                {({ push, remove }) => (
                  <div>
                    {values.certifications.map((form, index) => (
                      <div key={index}>
                        <Typography mt={3} mb={3} variant="h6">
                          {index + 1}) Sertifikasiya
                        </Typography>

                        <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                          <Grid sx={{ width: "100%" }}>
                            <FormControl fullWidth>
                              <InputLabel
                                id={`certifications[${index}].qualification`}
                              >
                                İxtisas *
                              </InputLabel>
                              <Field
                                as={Select}
                                name={`certifications[${index}].qualification`}
                                labelId={`certifications[${index}].qualification`}
                                label="İxtisas *"
                                id={`certifications[${index}].qualification`}
                                defaultValue=""
                                fullWidth
                              >
                                <MenuItem
                                  selected
                                  key={index}
                                  value={
                                    employeeById?.certifications[index]
                                      ?.qualification
                                  }
                                >
                                  {
                                    employeeById?.certifications[index]
                                      ?.qualification
                                  }
                                </MenuItem>
                                {/* {certification.length > 0 &&
                                  certification.sort().map((item, index) => (
                                    <MenuItem value={item} key={index}>
                                      {item}
                                    </MenuItem>
                                  ))} */}
                              </Field>
                            </FormControl>
                          </Grid>
                          <Grid sx={{ width: "100%" }}>
                            <Field
                              type="text"
                              name={`certifications[${index}].number`}
                              as={TextField}
                              fullWidth
                              label="Sertifikat nömrəsi *"
                              value={
                                employeeById?.certifications?.[index]?.number
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                          <Grid sx={{ width: "100%" }}>
                            <Field name={`certifications[${index}].issueDate`}>
                              {({ field }) => (
                                <DatePicker
                                  {...field}
                                  label="Sertifikatın verilmə tarixi *"
                                  inputFormat="dd/MM/yyyy"
                                  value={
                                    employeeById?.certifications?.[index]
                                      ?.issueDate || null
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      error={
                                        touched.certifications?.[index]
                                          ?.issueDate &&
                                        Boolean(
                                          errors.certifications?.[index]
                                            ?.issueDate
                                        )
                                      }
                                      helperText={
                                        touched.certifications?.[index]
                                          ?.issueDate &&
                                        errors.certifications?.[index]
                                          ?.issueDate
                                      }
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid sx={{ width: "100%" }}>
                            <Field name={`certifications[${index}].expireDate`}>
                              {({ field }) => (
                                <DatePicker
                                  {...field}
                                  label="Sertifikatın etibarlıq müddəti"
                                  inputFormat="dd/MM/yyyy"
                                  value={
                                    employeeById?.certifications?.[index]
                                      ?.expireDate || null
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      error={
                                        touched.certifications?.[index]
                                          ?.expireDate &&
                                        Boolean(
                                          errors.certifications?.[index]
                                            ?.expireDate
                                        )
                                      }
                                      helperText={
                                        touched.certifications?.[index]
                                          ?.expireDate &&
                                        errors.certifications?.[index]
                                          ?.expireDate
                                      }
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewCertification;
