// NOTE!!!
// PLEASE CONSIDER ALL COMMENTS BELOW
import React, { useEffect, useState } from "react";
import { getContract } from "../../../services/documentations/ContractServices";
import { toast } from "react-toastify";
import {
  exclusionOrderGenerator,
  structureOrderGenerator,
} from "./generatorFields/structureRequestOrder";
import { structureServiceGenerator } from "./generatorFields/structureServiceGenerator";
import { labourContractChangeGenerator } from "./generatorFields/labourContractChangeGenerator";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { approveLabourContract } from "../../../api/contractRequests/contractRequests";
import { COLORS_STATUS, REQUESTS_STATUS_TYPE } from "../../../constants";
import { findDocType } from "./helpers";
import { getEmployeeRequestManager } from "../../../services/vacation/VacationRequest";

const useRequestDialogHook = (
  id,
  loading,
  disableSigning,
  refreshPage,
  onClose,
  btnGroupLocationInApp
) => {
  const { currentEmployee } = useSelector((state) => state.commonThings);
  const [contractData, setContractData] = useState();
  const [fields, setFields] = useState([]);
  const [comments, setComments] = useState([]);
  const [status, setStatus] = useState(null);
  const [disableSign, setDisableSign] = useState(disableSigning);
  const [isGroupFields, setIsGroupFields] = useState(false);
  const [versionsList, setVersionsList] = useState([]);
  const [currentVersion, setCurrVersion] = useState("");
  const [relatedDocs, setRelatedDocs] = useState([]);
  const [navList, setNavList] = useState();
  const [currentNav, set_currentNav] = useState(0);
  const [sections, render_sections] = useState({
    info: true,
    doc: false,
    comment: false,
  });
  const [commmenDialog, renderCommmenDialog] = useState({
    type: null,
    isShow: false,
  });
  console.log(loading);
  // useEffect(() => {
  //   console.log(contractData);
  //   // generateData();
  //   Object.keys(contractData).length > 0 && loading?.setIsLoading(false);
  // }, [contractData]);

  const getData = async (_id, fetchHandler) => {
    // if (!id || !fetchHandler) return;
    try {
      const { data } = fetchHandler
        ? await fetchHandler(_id, true)
        : await getEmployeeRequestManager(_id, true);
      console.log(data);
      if (data) {
        generateData(data);
        setContractData(data);
        loading?.setIsLoading(false);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const generateData = (contractData) => {
    console.log(contractData);
    if (Object.keys(contractData || {}).length === 0) return;
    // generateFields(contractData);
    generateStatus(contractData);
    generateNavbarList(contractData);
    generateComments(contractData);
    generateVersions(contractData);
    setFields(contractData.data);
  };

  const generateNavbarList = (data) => {
    if (data?.docModel === "EmploymentRequest") {
      const navList = [
        {
          label: "Sorğu",
          value: 0,
        },
        { label: "Namizəd haqqında", value: data.originalRequest.employee.id },
      ];
      setNavList(navList);
    }
  };

  const generateVersions = (data) => {
    if (versionsList?.length > 0) return;
    const list = [];
    const versions = data.oldVersions;

    if (versions?.length === 0) {
      list.push({
        label: "1",
        value: id,
      });
    } else {
      versions?.map((item, index) => {
        list.push({
          label: `${index + 1}`,
          value: item.contract._id,
        });
      });

      list.push({
        label: `${versions?.length + 1}`,
        value: id,
      });
    }

    setCurrVersion(list[list.length - 1].value);

    setVersionsList(list);
  };
  const generateComments = (data) => {
    if (data?.docModel === "StructureRequest") {
      generateCommentsOfStructureReq(data);
      return;
    }

    const arr = [];
    const status = (status) => {
      switch (status) {
        case "approved":
          return "təsdiq edirəm";
        case "rejected":
          return "imtina edirəm";
        default:
          return "";
      }
    };
    const msg = "Şərh: ";

    data.approvementChain?.forEach((approver) => {
      const { firstname, lastname, position } = approver?.staff?.employee[0];
      if (approver.status === "pending") return;

      arr.push({
        label: `${msg}${firstname} ${lastname}${
          position?.name ? ` (${position.name})` : ""
        } - ${status(approver?.status)}`,
        value: approver.description,
      });
    });

    if (data.hrNotes?.length > 0) {
      data.hrNotes.map((item) => {
        arr.push({
          label: msg + "Yönləndirilmə səbəbi",
          value: item.description,
        });
      });
    }

    setComments([...arr]);
  };

  const generateCommentsOfStructureReq = (data) => {
    const arr = [];

    // data?.contract?.verifiers?.map((item) =>
    //   arr.push({
    //     label: `Təsdiqləyici -${" "}
    //     ${item?.employee?.firstname + " " + item?.employee?.lastname}`,
    //     value: item?.description,
    //   })
    // );

    data?.approvementChain?.map(
      (item) =>
        item.status !== "pending" &&
        arr.push({
          label: `Təsdiqləyici -${" "}
        ${
          item?.staff.employee?.firstname + " " + item?.staff.employee?.lastname
        }`,
          value: item?.description,
        })
    );

    const messageOwner =
      data.originalRequest?.creatorId?.firstname +
      " " +
      data.originalRequest?.creatorId?.lastname;

    data?.originalRequest?.message?.map((item) =>
      arr.push({
        label: `${messageOwner}: Struktur - ${" "}
        ${item?.key}`,
        value: item.value,
      })
    );

    setComments([...arr]);
  };

  const generateStatus = (data) => {
    const isRejected = isRejectedHandler(data);
    if (isRejected) {
      setStatus(isRejected);
      return;
    }

    const loggedStatus = checkLoggedUser(data);
    if (loggedStatus) {
      if (loggedStatus === "reRequested") {
        setStatus(data.status);
        return;
      }
      setStatus(loggedStatus);
    } else {
      setStatus(data.status);
    }
  };

  const checkLoggedUser = (data) => {
    const isIt = data?.approvementChain?.find(
      (item) => item.staff.employee[0].id === currentEmployee.id
    );

    if (isIt) return isIt.status;
    else return false;
  };

  const isRejectedHandler = (data) => {
    const isRejected = data?.approvementChain?.find(
      (item) => item.status === "rejected"
    );
    if (isRejected) return isRejected.status;
    else return false;
  };
  const navHandler = async (id) => {
    setFields([]);
    setComments();
    setStatus();
    setDisableSign(true);
    await getData(id, getContract);
  };
  const versionHandler = async (id) => {
    setFields([]);
    setComments();
    setStatus();
    // setDisableSign(true);
    setCurrVersion(id);
    await getData(id);
  };
  const generateFields = (data) => {
    // if (data.contractType.name === "structure-request-service") {
    //   const arr = loopingFields(data.data);
    //   const subjectsField = findSubjectsFields(data.subjects);
    //   const generalDetails = findGeneralDetails(data);
    //   arr.unshift(subjectsField, ...generalDetails);
    //   setFields([...arr]);
    //   return;
    // }
    if (data.contractType.name === "structure-request-order") {
      const fields = structureOrderGenerator(data);
      setFields(fields);
      return;
    }
    if (data.contractType.name === "structure-request-service") {
      const fields = structureServiceGenerator(data);
      setFields(fields);
      return;
    }
    if (data.contractType.name === "labourContractUpdate") {
      const fields = labourContractChangeGenerator(data);
      setFields(fields);
      return;
    }
    if (data.contractType.name === "exclusion-order") {
      const fields = exclusionOrderGenerator(data);
      setFields(fields);
      return;
    }
    setFields(data.data);
  };

  const checkVerifierStatus = (data) => {
    // signing buttons show only pending contract
    if (data.status !== "pending") {
      setDisableSign(true);
      return;
    }

    // logged user has on signers
    const founded = data.verifiers?.find(
      (item) => item.employee.id === currentEmployee._id
    );

    // if will not
    if (!founded || founded.status !== "pending") {
      setDisableSign(true);
      return;
    }

    // if (data.selfSign) {
    //   setDisableSign(true);
    //   return;
    // }

    setDisableSign(false);
  };
  const generateBgColor = () => {
    switch (status) {
      case "pending":
        return COLORS_STATUS.BLUE;
      case "approved":
        return COLORS_STATUS.GREEN;
      case "rejected":
        return COLORS_STATUS.RED;
      case REQUESTS_STATUS_TYPE.ReRequestedToCreator:
        return COLORS_STATUS.RED;
      case REQUESTS_STATUS_TYPE.CancelledByHR:
        return COLORS_STATUS.RED;
      case REQUESTS_STATUS_TYPE.PendingChainApprove:
        return COLORS_STATUS.BLUE;
      case REQUESTS_STATUS_TYPE.PendingOrder:
        return COLORS_STATUS.BLUE;
      case REQUESTS_STATUS_TYPE.PendingOrderApprove: // ????????????????????
        return COLORS_STATUS.GREEN;
      case REQUESTS_STATUS_TYPE.ReRequestedToManager:
        return COLORS_STATUS.ORANGE;
      case REQUESTS_STATUS_TYPE.RejectedByChainUser:
        return COLORS_STATUS.RED;
      case REQUESTS_STATUS_TYPE.UpdatedByCreator:
        return COLORS_STATUS.RED;
      default:
        return COLORS_STATUS.GREY;
    }
  };
  const generateHeader = () => {
    // const docType = findDocType(contractData?.contractType?.type);
    switch (status) {
      case "pending":
        return "Təsdiq gözləyən sorğu";
      case "approved":
        return "Təsdiqlənmiş sorğu";
      case "rejected":
        return "İmtina edilmiş sorğu";
      case REQUESTS_STATUS_TYPE.ReRequestedToCreator:
        return "Əməkdaşa yönləndirilmiş sorğu";
      case REQUESTS_STATUS_TYPE.CancelledByHR:
        return "HR tərəfindən imtina edilmiş";
      case REQUESTS_STATUS_TYPE.PendingChainApprove:
        return "Təsdiq gözləyən sorğu";
      case REQUESTS_STATUS_TYPE.PendingOrder:
        return "Sorğu ilə bağlı əmr gözlənilir";
      case REQUESTS_STATUS_TYPE.PendingOrderApprove:
        return "İmza gözləyən əmr"; // ??????????
      case REQUESTS_STATUS_TYPE.ReRequestedToManager:
        return "Yenidən yönləndirilmiş sorğu";
      case REQUESTS_STATUS_TYPE.RejectedByChainUser:
        return "İmtina edilmiş sorğu";
      case REQUESTS_STATUS_TYPE.UpdatedByCreator:
        return "Əməkdaş tərəfindən yenilənmiş sorğu";
      default:
        return "Sorğu";
    }
  };

  const docNumberHandler = () => {
    const docNo = contractData
      ? validateValue(
          contractData?.data?.contract_general_info?.map((item) => {
            if (item.key === "docNumber") return item.value;
          })
        )
      : validateValue(
          contractData?.data?.document_general_info?.map((item) => {
            if (item.key === "docNumber") {
              return item.value;
            }
          })
        );
    return docNo;
  };

  return {
    contractData,
    setContractData,
    fields,
    status,
    relatedDocs,
    setRelatedDocs,
    versionHandler,
    navHandler,
    render_sections,
    sections,
    docNumberHandler,
    generateBgColor,
    generateHeader,
    renderCommmenDialog,
    // appLabourContract,
    comments,
    getData,
    navList,
  };
};

export default useRequestDialogHook;

const normalizeDocName = (key) => {
  switch (key) {
    case "employment-application":
      return "Ərizə";
    case "labour-contract":
      return "Müqavilə";
    case "labour-order":
      return "Əmr";
    case "vacation-order":
      return "Məzuniyyət əmri";
    case "vacation-application":
      return "Məzuniyyət ərizəsi";
    case "labour-termination-order":
      return "Xitam əmri";
    default:
      return key;
  }
};

const validateValue = (value) => {
  return value && value.length > 0 ? value : "";
};
