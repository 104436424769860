import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../constants";
import { Dialog } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import DialogContent from "./DialogContent";
import CommentDialog from "./dialogParts/parts/CommentDialog";
import useDocumentDialogHook from "./useDocumentDialogHook";

export default function DocumentDialog({
  refreshPage,
  data,
  open,
  onClose,
  fetchHandler = undefined,
  id,
  disableSigning = false,
  navList = [],
  buttonGroup,
  setStatus,
}) {
  const [isLoading, setIsLoading] = useState(true);

  const {
    contractData,
    setContractData,
    fields,
    status,
    docNumberHandler,
    versionHandler,
    navHandler,
    currentNav,
    generateBgColor,
    generatedHeader,
    versionsList,
    currentVersion,
    setRelatedDocs,
    relatedDocs,
    render_sections,
    sections,
    comments,
    getData,
    // fetchHandler,
  } = useDocumentDialogHook(navList, id, { isLoading, setIsLoading });
  setStatus && setStatus(status);
  const onCloseHandler = () => {
    onClose();
    setContractData(null);
    setRelatedDocs([]);
  };

  let docNames = [
    "structure-request-order",
    "structure-request-service",
    "employment-application",
    "labour-contract",
    "labour-order",
    "labourContractUpdate",
    "vacation-order",
    "vacation-application",
  ];

  const [commmenDialog, renderCommmenDialog] = useState({
    type: null,
    isShow: false,
  });

  let commDialogPaperStyle = {
    width: 1000,
    height: "auto",
    opacity: 0.95,
    borderRadius: "10px",
  };
  useEffect(() => {
    setIsLoading(true);
    // id ? setData(id) : setData(navList?.[0]?.value);
    id ? getData(id, fetchHandler) : getData(navList?.[0]?.value);
  }, [id, fetchHandler]);

  return (
    <>
      <Dialog
        open={open}
        sx={{
          overflow: "auto",
          width: "100%",
        }}
        className="doc-dialog"
        onClose={onCloseHandler}
        // PaperProps={{
        //   sx: [
        //     styles.paper,
        //     {
        //       backgroundColor: !loading ? options.bgColor : "grey",
        //       opacity: loading && 0.9,
        //       display:
        //         (showCommDialog.isShow || showSignRequestDialog) && "none",
        //     },
        //   ],
        // }}
      >
        {docNames?.includes(contractData?.contractType?.name) && (
          <DialogContent
            fields={fields}
            href={`${BASE_URL}/employee/contract/${contractData?._id}/download`}
            buttonGroup={!isLoading ? buttonGroup : []}
            render_sections={{ set: render_sections, state: sections }}
            comments={comments}
            contractNo={contractData && docNumberHandler()}
            generateBgColor={generateBgColor}
            generateHeader={generatedHeader}
            version={{
              versionsList,
              versionHandler,
              currentVersion,
            }}
            navigation={{
              navList: relatedDocs,
              navHandler,
              currentNav,
            }}
          />
        )}
      </Dialog>
      <Dialog
        open={commmenDialog.isShow}
        onClose={() => {
          renderCommmenDialog({ isShow: false, type: undefined });
          // setShowSignRequestDialog(false);
        }}
        PaperProps={{ style: commDialogPaperStyle }}
        maxWidth={"1000px"}
      >
        <CommentDialog
          // setShowSignRequestDialog={() => setShowSignRequestDialog(true)}
          type={commmenDialog.type}
          id={id}
          onClose={() => {
            renderCommmenDialog({ isShow: false, type: undefined });
          }}
          closeSigningDialog={onClose}
          // onSubmitHandler={commentsProps.submitHandler}
        />
      </Dialog>
    </>
  );
}
