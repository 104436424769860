import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  checkBusinessTripDataList,
  createBusinessTrip,
  getEmployeeList,
} from "../pages/leaves/services";
import { removeEmptyStrings } from "../utils/removeEmptyString";
import dayjs from "dayjs";
import { getStaticData } from "../services/employees/StaticDataServices";
import moment from "moment";

function useBusinessTrip() {
  const [empData, setEmpData] = useState([]);
  const [country, setCountry] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [foreignCity, setForeignCity] = useState("");
  const [submited, setSubmited] = useState(false);
  const [show_Accordion, set_show_Accordion] = useState(false);
  const [disabledRanges, setDisabledRanges] = useState([]);
  const [disabledReasonMessage, setDisabledReasonMessage] = useState([]);
  const [leave_state, set_leave_state] = useState({
    staffId: "",
    staff: [],
    substituteStaff: [],
    employeeId: "",
    substituteId: "",
    substituteTabelNo: "",
    empTabelNo: "",
    substitute: [],
    isChecked: false,
    withinCounty: [],
    substituteStaffId: "",
    withinLocation: "",
    positionName: "",
    substitutePositionName: "",
    tripReason: "",
    tripStartDate: "",
    tripEndDate: "",
    returnToWorkDate: "",
    tripDuration: "",
    foreignTripDetails: "",
  });
  const filterModel = [
    {
      column: "status",
      operator: "equalsTo",
      value: "active",
    },
  ];
  const getData = async () => {
    try {
      const res = await getStaticData(["travelAllowances"]);
      const respons = await getEmployeeList();
      const resp = await getStaticData(["domesticTripLocations"]);
      setCountry(res?.data?.[0]?.data);
      setEmpData(respons.data);
      set_leave_state({ ...leave_state, withinCounty: resp?.data?.[0]?.data });
    } catch (err) {
      console.log(err);
    }
  };
  const getDateList = async () => {
    if (leave_state?.employeeId && leave_state?.substituteId) {
      const dateList = await checkBusinessTripDataList(
        leave_state?.employeeId,
        leave_state?.substituteId
      );

      setDateList(dateList?.data);
    }
  };
  const processDisabledRangesAndMessages = () => {
    // Ensure to clear previous ranges and messages
    setDisabledRanges([]);
    setDisabledReasonMessage([]);

    // Loop through dateList and process unactiveDates
    for (let index = 0; index < dateList.length; index++) {
      const element = dateList[index];
      if (element?.unactiveDates?.length > 0) {
        for (const item of element.unactiveDates) {
          for (const key in item) {
            setDisabledRanges((prevRanges) => [
              ...prevRanges,
              {
                start: dayjs(item?.[key]?.startDate).toISOString(),
                end: dayjs(item?.[key]?.endDate).toISOString(),
              },
            ]);
            setDisabledReasonMessage((prevMessages) => [
              ...prevMessages,
              item?.[key]?.message,
            ]);
          }
        }
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    processDisabledRangesAndMessages();
  }, [dateList]);
  useEffect(() => {
    getDateList();
  }, [leave_state?.employeeId, leave_state?.substituteId]);
  //   const getEmployeeData = async () => {
  //     const filter = encodeURIComponent(JSON.stringify(filterModel));
  //     try {
  //       const response = await getEmployees({ filter: filter });

  //       setEmpData(response?.employees);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  const isLeaveStateEmpty = () => {
    return (
      !leave_state.staffId ||
      !leave_state.employeeId ||
      !leave_state?.substituteStaffId ||
      !leave_state?.substituteId ||
      !leave_state?.tripReason ||
      !leave_state?.tripStartDate ||
      !leave_state?.tripEndDate ||
      !leave_state?.tripDuration ||
      !leave_state?.returnToWorkDate
    );
  };
  async function handleSubmit(e) {
    e.preventDefault();
    setSubmited(true);
    const modifiedValues = {
      staff: leave_state?.staffId,
      employee: leave_state?.employeeId,
      substituteStaff: leave_state?.substituteStaffId,
      substituteEmployee: leave_state?.substituteId,
      tripReason: leave_state?.tripReason,
      isForeignCountry: leave_state?.isChecked,
      domesticTripDetails: !leave_state?.isChecked
        ? [
            {
              location: leave_state?.withinLocation,
            },
          ]
        : "",
      foreignTripDetails: leave_state?.isChecked
        ? [
            {
              name: leave_state?.foreignTripDetails?.name
                ? leave_state?.foreignTripDetails?.name
                : leave_state?.foreignTripDetails,
              cities: leave_state?.foreignTripDetails?.cities
                ? [
                    {
                      name: foreignCity,
                    },
                  ]
                : [],
            },
          ]
        : "",
      tripStartDate: moment(leave_state?.tripStartDate).format("MM/DD/YYYY"),
      tripEndDate: moment(leave_state?.tripEndDate).format("MM/DD/YYYY"),
      returnToWorkDate: moment(leave_state?.returnToWorkDate).format(
        "MM/DD/YYYY"
      ),
      tripDuration: Number(leave_state?.tripDuration),
    };

    const result = removeEmptyStrings(modifiedValues);

    try {
      await createBusinessTrip(result);
      toast.success("Yeni məlumat uğurla əlavə edildi!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      set_leave_state({
        staffId: "",
        staff: [],
        substituteStaff: [],
        employeeId: "",
        substituteId: "",
        substituteTabelNo: "",
        empTabelNo: "",
        substitute: [],
        isChecked: false,
        withinCounty: [],
        substituteStaffId: "",
        withinLocation: "",
        positionName: "",
        substitutePositionName: "",
        tripReason: "",
        tripStartDate: "",
        tripEndDate: "",
        returnToWorkDate: "",
        tripDuration: "",
        foreignTripDetails: "",
      });
      set_show_Accordion(false);
      setDisabledRanges([]);
      setDisabledReasonMessage([]);
    } catch (err) {
      err?.response?.data?.errors.map((item) => {
        return toast.error(item?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
    setSubmited(false);
  }
  function calcTripDuration() {
    const tripStartDate = new Date(leave_state?.tripStartDate);
    const tripEndDate = new Date(leave_state?.tripEndDate);

    const resultTripDuration =
      (tripEndDate - tripStartDate) / (1000 * 60 * 60 * 24);

    // Only update if there's a valid duration
    if (!isNaN(resultTripDuration)) {
      set_leave_state((prevState) => ({
        ...prevState,
        tripDuration: resultTripDuration,
      }));
    }
  }

  useEffect(() => {
    calcTripDuration();
  }, [leave_state?.tripStartDate, leave_state?.tripEndDate]);

  const shouldDisableDate = (date) => {
    const targetDate = new Date(date);
    const targetUTCDate = Date.UTC(
      targetDate.getUTCFullYear(),
      targetDate.getUTCMonth(),
      targetDate.getUTCDate()
    );

    return disabledRanges.some((range) => {
      const startDate = new Date(range.start);
      const endDate = new Date(range.end);
      startDate.setUTCDate(startDate.getUTCDate() - 1);
      endDate.setUTCDate(endDate.getUTCDate() - 1);
      const startUTCDate = Date.UTC(
        startDate.getUTCFullYear(),
        startDate.getUTCMonth(),
        startDate.getUTCDate()
      );
      const endUTCDate = Date.UTC(
        endDate.getUTCFullYear(),
        endDate.getUTCMonth(),
        endDate.getUTCDate()
      );
      return targetUTCDate >= startUTCDate && targetUTCDate <= endUTCDate;
    });
  };
  return {
    country,
    empData,
    submited,
    leave_state,
    handleSubmit,
    setForeignCity,
    show_Accordion,
    set_leave_state,
    isLeaveStateEmpty,
    shouldDisableDate,
    set_show_Accordion,
    disabledReasonMessage,
  };
}

export default useBusinessTrip;
